/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'

/**
 * $t('enum.E_Status.Normal')
 * $t('enum.E_Status.Suspend')
 */
export enum E_Status {
    /**
     * 正常
     */
    Normal = 0,
    /**
     * 停用
     */
    Suspend = 1,
}

/**
 * $t('enum.E_StatusString.Normal')
 * $t('enum.E_StatusString.Suspend')
 */
export enum E_StatusString {
    /**
     * 正常
     */
    Normal = '0',
    /**
     * 停用
     */
    Suspend = '1',
}

export const E_StatusString__Invert = _.invert(E_StatusString)

/**
 * 图例类型
 * $t('enum.E_ChartTypeString.ACCELERATION_TIME')
 * $t('enum.E_ChartTypeString.ACCELERATION_FREQUENCY')
 * $t('enum.E_ChartTypeString.VELOCITY_TIME')
 * $t('enum.E_ChartTypeString.VELOCITY_FREQUENCY')
 * $t('enum.E_ChartTypeString.ENVELOPE_SIGNAL')
 * $t('enum.E_ChartTypeString.ENVELOPE_FREQ')
 * $t('enum.E_ChartTypeString.ACCELERATION')
 * $t('enum.E_ChartTypeString.VIBRATIO_INNTENSITY')
 * $t('enum.E_ChartTypeString.DISPLACEMENT')
 * $t('enum.E_ChartTypeString.DISPLACEMENT_FREQUENCY')
 * $t('enum.E_ChartTypeString.TEMPERATURE')
 * $t('enum.E_ChartTypeString.TEMPERATURE_RISE')
 * $t('enum.E_ChartTypeString.KURTOSIS')
 * $t('enum.E_ChartTypeString.CREST_FACTOR')
 * $t('enum.E_ChartTypeString.ENVELOPE_DEMODULATION') 
 * $t('enum.E_ChartTypeString.SPEED')
 * $t('enum.E_ChartTypeString.ANGLE')
 * $t('enum.E_ChartTypeString.BATTERY')
 */
export enum E_ChartTypeString {
    /** 加速度时域*/
    ACCELERATION_TIME = 'ACCELERATION_TIME',
    /** 加速度频域*/
    ACCELERATION_FREQUENCY = 'ACCELERATION_FREQUENCY',
    /** 速度时域*/
    VELOCITY_TIME = 'VELOCITY_TIME',
    /** 速度频域*/
    VELOCITY_FREQUENCY = 'VELOCITY_FREQUENCY',
    /** 包络频谱*/
    ENVELOPE_SIGNAL = 'ENVELOPE_SIGNAL',
    /** 包络信号*/
    ENVELOPE_FREQ = 'ENVELOPE_FREQ',


    /**
    * 加速度
    */
    ACCELERATION = 'ACCELERATION',
    /**
    * 振动烈度
    */
    VIBRATIO_INNTENSITY = 'VIBRATIO_INNTENSITY',
    /**
    * 位移
    */
    DISPLACEMENT = 'DISPLACEMENT',

    /**
     * 位移频谱
     */
    DISPLACEMENT_FREQUENCY = 'DISPLACEMENT_FREQUENCY',
    /**
    * 温度
    */
    TEMPERATURE = 'TEMPERATURE',
    /**
    * 温升
    */
    TEMPERATURE_RISE = 'TEMPERATURE_RISE',
    /**
    * 峭度
    */
    KURTOSIS = 'KURTOSIS',
    /**
    * 波峰因数
    */
    CREST_FACTOR = 'CREST_FACTOR',
    /**
    * 包络解调值
    */
    ENVELOPE_DEMODULATION = 'ENVELOPE_DEMODULATION',
    /**
    * 转速
    */
    SPEED = 'SPEED',
    /**
    * 角度
    */
    ANGLE = 'ANGLE',
    /**
    * 电压
    */
    BATTERY = 'BATTERY',
}


/**
 * 特征值
 * $t('enum.E_Eigenvalue.Acceleration')
 * $t('enum.E_Eigenvalue.VibrationSeverity')
 * $t('enum.E_Eigenvalue.Offset')
 * $t('enum.E_Eigenvalue.Temperature')
 * $t('enum.E_Eigenvalue.TemperatureIncrease')
 * $t('enum.E_Eigenvalue.Kurtosis')
 * $t('enum.E_Eigenvalue.CrestFactor')
 * $t('enum.E_Eigenvalue.EnvelopeDemodulation')
 * $t('enum.E_Eigenvalue.RotationalSpeed')
 * $t('enum.E_Eigenvalue.Angle')
 * $t('enum.E_Eigenvalue.Battery') 
 * $t('enum.E_Eigenvalue.HighFrequencyAcceleration')
 * $t('enum.E_Eigenvalue.Ambienttem')
 * $t('enum.E_Eigenvalue.Rssi')
 * * $t('enum.E_Eigenvalue.VFreq')
 * $t('enum.E_Eigenvalue.AccelerationTimeDomain')
 * $t('enum.E_Eigenvalue.AccelerationFrequencyDomain')
 * $t('enum.E_Eigenvalue.VelocityDomain')
 * $t('enum.E_Eigenvalue.VelocityFrequencyDomain')
 * $t('enum.E_Eigenvalue.EnvelopeSpectrum')
 * $t('enum.E_Eigenvalue.EnvelopeSignal')
 * $t('enum.E_Eigenvalue.DisplacementTimeDomain')
 * $t('enum.E_Eigenvalue.DisplacementSpectrum')
 * $t('enum.E_Eigenvalue.AxialDisplacement')
 */
export enum E_Eigenvalue {
    /**
     * 加速度*
     */
    Acceleration = 1,
    /**
     * 振动烈度*
     */
    VibrationSeverity = 2,
    /**
     * 位移峰峰值*
     */
    Offset = 3,
    /**
     * 温度*
     */
    Temperature = 4,
    /**
     * 温升*
     */
    TemperatureIncrease = 5,
    /**
     * 峭度*
     */
    Kurtosis = 6,
    /**
     * 波峰因数*
     */
    CrestFactor = 7,
    /**
     * 包络解调值*
     */
    EnvelopeDemodulation = 8,
    /**
     * 转速*
     */
    RotationalSpeed = 9,
    /**
     * 倾角
     */
    Angle = 10,
    /**
     * 电压
     */
    Battery = 11, 
    /**
     * 高频Z轴加速度
     */
    HighFrequencyAcceleration = 12,

    /**
     * 环境温度
     */
    Ambienttem = 13,

    /**
     * 信号
     */
    Rssi = 14,

    /**
     * 振动频率(Hz)
     */
    VFreq = 15,

    /**
     * 轴位移
     */
    AxialDisplacement = 16,

    /**
     * 加速度时域
     */
    AccelerationTimeDomain = 100,
    /**
     * 加速度频域
     */
    AccelerationFrequencyDomain = 101,
    /**
     * 速度时域
     */
    VelocityDomain = 102,
    /**
     * 速度频域
     */
    VelocityFrequencyDomain = 103,
    /**
     * 包络频谱
     */
    EnvelopeSpectrum = 104,
    /**
     * 包络信号
     */
    EnvelopeSignal = 105,
    /**
     * 位移时域
     */
    DisplacementTimeDomain = 106,
    /**
     * 位移频谱
     */
    DisplacementSpectrum = 107,
    
}

/**
 * 诊断错误枚举
 * $t('enum.E_FaultAlysisStatistics.bearingBreath')
 * $t('enum.E_FaultAlysisStatistics.misaligned')
 * $t('enum.E_FaultAlysisStatistics.looseness')
 * $t('enum.E_FaultAlysisStatistics.imbalance')
 * $t('enum.E_FaultAlysisStatistics.unevenBreath')
 * $t('enum.E_FaultAlysisStatistics.resonance')
 * $t('enum.E_FaultAlysisStatistics.fanBreath')
 * $t('enum.E_FaultAlysisStatistics.pumpBreath')
 * $t('enum.E_FaultAlysisStatistics.gearBBreath')
 * $t('enum.E_FaultAlysisStatistics.gearFBreath')
 * $t('enum.E_FaultAlysisStatistics.gearMBreath')
 */
export enum E_FaultAlysisStatistics {
    /**
     * 轴承故障
     */
    bearingBreath = 'bearingBreath',
    /**
     * 不对中
     */
    misaligned = 'misaligned',
    /**
     * 设备基础松动
     */
    looseness = 'looseness',
    /**
     * 不平衡
     */
    imbalance = 'imbalance',
    /**
     * 转定子气隙不均
     */
    unevenBreath = 'unevenBreath',
    /**
     * 共振
     */
    resonance = 'resonance',

    //风机
    /**
     * 风机叶片摩擦
     */
    fanBreath = 'fanBreath',

    //水泵
    /**
     * 水泵叶片摩擦
     */
    pumpBreath = 'pumpBreath',

    //齿轮箱
    /**
     * 齿轮箱不对中
     */
    gearBBreath = 'gearBBreath',
    /**
     * 齿轮负载高
     */
    gearFBreath = 'gearFBreath',
    /**
     * 齿轮磨损
     */
    gearMBreath = 'gearMBreath',
}
