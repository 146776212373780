export function fixRSSI(value: number) {
    var v = undefined;
    if (value == 0) {
        v = -113
    } else if (value > 0 && value <= 31) {
        v = value * 2 - 113
    } else if (value > 31) {
        v = undefined
    }
    console.log({
        raw: value,
        fixed: v,
    })
    return v;
}
