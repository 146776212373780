import { E_Theme, getCurrentTheme } from "@/utils/theme"

export const LineConfig = {
    showSymbol: false,
    symbolSize: 2,
    lineStyle: {
        width: 1,
    }
}

export const LineSelectedConfig = {
    showSymbol: true,
    symbolSize(param) {
        if (param.selected) {
            return 8
        } else {
            return 0
        }
    },
    lineStyle: {
        width: 1,
    }
}

export const AxisSplitLine = {
    splitLine: {
        lineStyle: {
            opacity: getCurrentTheme() === E_Theme.Dark ? 0.3 : 0.8,
            type: 'dashed'
        }
    },
}
