/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { ECharts } from 'echarts'
import Vue from 'vue'

export namespace Events {
    type Action = () => void

    export function addEventListener(elem: Window | HTMLElement | Document, event: string, fn: (e: any) => void, option?) {
        elem.addEventListener(event, fn, option)
        return () => {
            elem.removeEventListener(event, fn, option)
        }        
    }

    export function listenOnComponent(vue: Vue, elem: Window | HTMLElement | Document, event: string, fn: (e: any) => void, option?) {
        elem.addEventListener(event, fn, option)
        vue.$on('hook:beforeDestroy', () => {
            elem.removeEventListener(event, fn, option)
        })
    }

    export function disposeOnComponent(vue: Vue, fn: Action) {
        vue.$on('hook:beforeDestroy', () => {
            fn()
        })
    }

    export function disposeEChartsOnComponent<T extends Vue, K extends keyof T>(vue: T, ...chartFileds: K[]) {
        disposeOnComponent(vue, () => {
            _.each(chartFileds, chartFiled => {
                if (vue[chartFiled]) {
                    (vue[chartFiled] as any as ECharts).dispose()
                    vue[chartFiled] = undefined
                }
            })
        })
    }
    export function disposeEChartInstanceOnComponent<T extends Vue>(vue: T, chart) {
        disposeOnComponent(vue, () => {
            chart.dispose()
        })
    }

    export function addEventListenerEx(vue: Vue, elem, type, fn) {
        elem.addEventListener(type, fn)
        disposeOnComponent(vue, () => {
            elem.removeEventListener(type, fn)
        })
    }
}
