/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Ref, Prop, } from 'vue-property-decorator'

import Target from "../popup/Target.vue";
import { E_ChartTypeString } from '@/types';
import { IWaveSpectrumQuery } from '../types';
import * as echarts from 'echarts'
import { formatDateTime } from '@/utils/datetime';
import { isCheckQueryFailedAndReport } from '@/view_helper/wave';
import { EChartImpl } from '@/components/EChart/EChartImpl';
import { Toolbox } from '@/utils/echarts';
import { MultipleIndicatorsTrendDimensions, buildSeries } from '../component/config';
import { getPrimaryColor } from '@/utils/color';

function makeForm() {
    return {
        chartTypes: [E_ChartTypeString.ACCELERATION] as E_ChartTypeString[],
    }
}

@Component({
    name: 'MultipleIndicatorsTrend',
    components: {
        Target,
    }
})
export class MultipleIndicatorsTrendImpl extends Vue {
    @Prop({
        type: Object,
    }) query: IWaveSpectrumQuery

    dialog = false // 过滤

    form = makeForm()

    onSearch() {
        this.updateChart()
    }

    onReset() {
        this.form = makeForm()
        this.chart.setOption({}, true)
    }

    onConfirmTarget(value: E_ChartTypeString[]) {
        this.form.chartTypes = value
        this.dialog = false
        this.onSearch()
    }

    openRetrieval() {
        this.dialog = true;
    }
    closeRetrieval() {
        this.dialog = false;
    }

    @Ref()
    chartRef: HTMLDivElement

    chart: echarts.ECharts

    async requestFn(chart: echarts.ECharts) {
        if (isCheckQueryFailedAndReport(this, this.query)) {
            return
        }
        const {
            mappingId,
            timeRange,
        } = this.query
        const {
            chartTypes,
        } = this.form
        const res = await this.$api.waveform.queryWaveformCharacteristicByMany({
            mappingId,
            startTime: formatDateTime(timeRange[0]),
            endTime: formatDateTime(timeRange[1]),
            chartType: _.join(chartTypes, ',')
        })
        const resData = res.data
        let option: echarts.EChartsOption = {
            toolbox: Toolbox as any,
            dataset: {
                dimensions: MultipleIndicatorsTrendDimensions,
                source: resData as any,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                },
            },
            dataZoom: [
                {
                    type: "inside",
                    start: 0,
                    end: 100,
                },
                {
                    type: "slider",
                    start: 0,
                    end: 100,
                    height: 8,
                    bottom: 60,
                },
            ],
            grid: {
                top: 40,
                left: 40,
                right: 40,
                bottom: 80
            },
            legend: {
                left: "center",
                bottom: 25,
                textStyle: {
                    color: getPrimaryColor(),
                },
            },
            xAxis: [
                {
                    type: 'category',
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.$t('common.vibration') as string,
                    nameLocation: 'middle',
                    nameGap: 40,
                    splitLine: {
                        lineStyle: {
                            color: 'rgb(220, 220,220)',
                            opacity: 0.3,
                            type: 'dashed'
                        }
                    }
                },
                {
                    type: 'value',
                    name: this.$t('charts.tempperatureAndFrequencyConversion') as string,
                    nameLocation: 'middle',
                    nameGap: 40,
                    nameRotate: 270,
                    splitLine: {
                        lineStyle: {
                            color: 'rgb(220, 220,220)',
                            opacity: 0.3,
                            type: 'dashed'
                        }
                    }
                },
            ],
            series: buildSeries(this, this.form.chartTypes) as any,
        }
        // console.log(option)
        chart.setOption(option, true, true)
    }

    @Ref()
    echartRef: EChartImpl

    hasRequest = false

    updateChart() {
        this.echartRef.request()
        this.hasRequest = true
    }
}
