import Vue from "vue";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faUserSecret,
    faChartPie,
    faCloudUpload,
    faWaveSquare,
    faImage,
    faMicrophone,
    faMobile,
    faAnchor,
    faStethoscope,
    faChartArea,
    faServer,
    faMicrochip,
    faImages,
    faRightFromBracket,
    faCircle,
    faCircleCheck,
} from '@fortawesome/free-solid-svg-icons'

library.add(faUserSecret,
    faChartPie,
    faCloudUpload,
    faWaveSquare,
    faImage,
    faMicrophone,
    faMobile,
    faAnchor,
    faStethoscope,
    faChartArea,
    faServer,
    faMicrochip,
    faImages,
    faRightFromBracket,
    faCircle,
    faCircleCheck)
Vue.component('font-awesome-icon',
    FontAwesomeIcon)
