/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { isFailedAndReport, isFailedCreateOrEditAndReport, isFailedListAndReport } from "@/api/helper"
import { IDataWrapper } from "@/api/types"
import { E_DialogStage } from "@/utils/dialog"
import { getCurrentInstance } from "vue"
import { LocaleMessages } from "vue-i18n"

/**
 * 通用报告hook 
 */
export function useReport() {
    const instance = getCurrentInstance()

    return {
        /**
         * 测试数据是否有问题并且报告         
         */
        isFailedListAndReport<T>(res: IDataWrapper<T>, checkData = false) {
            return isFailedListAndReport(instance.proxy, res, checkData)
        },
        /**
         * 测试数据是否有问题并且报告         
         */
        isFailedAndReport<T>(res: IDataWrapper<T>) {
            return isFailedAndReport(instance.proxy, res)
        },
        /**
         * 测试数据是否有问题并且报告         
         */
        isFailedCreateOrEditAndReport<T>(res: IDataWrapper<T>, stage: E_DialogStage) {
            return isFailedCreateOrEditAndReport(instance.proxy, res, stage)
        },
        /**
         * 成功提示
         */
        showSuccessMessage(message: string | LocaleMessages) {
            return instance.proxy.$message({
                type: 'success',
                message: message as string,
            })
        },
        /**
         * 警告提示
         */
        showWarningMessage(message: string | LocaleMessages) {
            return instance.proxy.$message({
                type: 'warning',
                message: message as string,
            })
        },
        /**
         * 错误提示
         */
        showErrorMessage(message: string | LocaleMessages) {
            return instance.proxy.$message({
                type: 'error',
                message: message as string,
            })
        }
    }
}
