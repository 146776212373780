import _ from 'lodash'
import { ISensorKlassTableItem } from "@/constants/sensor"
import { E_Eigenvalue } from "@/types"
import { EVT40_img } from '@/assets/sensor';
import { E_SensorKlass } from "@/api/moudules/sensor.types";
// 新余钢铁
export enum E_XYGT_TagName {
    ZT = 'ZT',
    VT = 'VT',
}

//  轴位移单位
export function tagName2AxialDisplacementUnit(tagName: string) {
    tagName = (tagName || '').trim()
    if (_.startsWith(tagName, E_XYGT_TagName.ZT)) {
        return 'mm'
    } else if (_.startsWith(tagName, E_XYGT_TagName.VT)) {
        return 'um'
    } else {
        return ''
    }
}

export function isXYGT(klass: E_SensorKlass) {
    return _.includes([
        E_SensorKlass.XYGT_AD,
        E_SensorKlass.XYGT_RPM,
        E_SensorKlass.XYGT_TEMP,
    ], klass)
}

export const XYGT_SensorKlassTable: readonly ISensorKlassTableItem[] = Object.freeze([
    {
        klass: E_SensorKlass.XYGT_RPM,
        useInstallMethod: false,
        modelImg: EVT40_img,
        hasAxis: false,
        hasCollectMode: false,
        hasOpenLearn: false,
        hasFaultDiagnosis: false,
        hasSleepT: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.RotationalSpeed,
        ]
    },
    {
        klass: E_SensorKlass.XYGT_AD,
        useInstallMethod: false,
        modelImg: EVT40_img,
        hasAxis: true,
        hasCollectMode: false,
        hasOpenLearn: false,
        hasFaultDiagnosis: false,
        hasSleepT: true,
        hasDiagnose: true,
        hasTagName: true,
        eigenvalueFeatures: [
            E_Eigenvalue.AxialDisplacement,
        ]
    },
    {
        klass: E_SensorKlass.XYGT_TEMP,
        useInstallMethod: false,
        modelImg: EVT40_img,
        hasAxis: false,
        hasCollectMode: false,
        hasOpenLearn: false,
        hasFaultDiagnosis: false,
        hasSleepT: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Temperature,
        ]
    },
])
