<template>
    <div class="button" v-bind="$attrs" v-on="$listeners">
        <img src="@/assets/image/button/add.svg" class="icon" />{{ Text }}
    </div>
</template>
<script>
export default {
    name: "new-button",
    props: {
        text: {
            type: String,
        }
    },
    computed: {
        Text() {
            if(!this.text) {
                return this.$t('buttons.newEdit')
            }
            return this.text
        }
    }
}
</script>
<style lang="scss" scoped>
.button {
    display: inline-flex;
    background: rgba(64, 149, 229, 1);
    color: rgba(239, 239, 239, 1);
    padding: 0 8px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    box-sizing: border-box;
    
    font-weight: 500;
    border-radius: 3px;
    border: 1px solid var(--border-color);
    align-items: center;

    &:active {
        background: rgba(64, 149, 229, 0.81);
    }

    .icon {
        margin-left: 8px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
    }
}
</style>