/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Ref, Prop, } from 'vue-property-decorator'

import DataRetrieval from "@/components/DataRetrieval/DataRetrieval.vue";
import TrendWaveForm from "./subview/TrendWaveForm.vue";
import TimeDomainWaveform from "./subview/TimeDomainWaveForm.vue";
import FrequencySpectrum from "./subview/FrequencySpectrum.vue";
import Waterfall from "./subview/Waterfall.vue";
import Temperature from "./subview/Temperature.vue";
import MultipleIndicatorsTrend from "./subview/MultipleIndicatorsTrend.vue";
import { IMappingItem } from '@/api/moudules/mapping.types'
import { ICompanyItem } from '@/api/moudules/company.types';
import { IAreaItem } from '@/api/moudules/area.types';
import { isEmptyStringOrNil } from '@/utils/lang';
import { isCheckQueryFailedAndReport } from '@/view_helper/wave';
import { ISelectedData } from '@/components/DataRetrieval/DataRetrievalImpl';
import { isFailed } from '@/api/helper';
import EigenvalueShow from '@/popup/EigenvalueShow.vue';
import Cepstrum from './subview/Cepstrum.vue';
import AxisOrbit from './subview/AxisOrbit.vue'

function makeQueryForm() {
    return {
        companyId: null,
        areaId: null,

        mappingId: null,
        timeRange: null,
    }
}

/**
 * $t('enum.E_WaveTabs.TrendWave')
 * $t('enum.E_WaveTabs.TimeDomainWave')
 * $t('enum.E_WaveTabs.FrequencySpectrum')
 * $t('enum.E_WaveTabs.Waterfall')
 * $t('enum.E_WaveTabs.Temperature')
 * $t('enum.E_WaveTabs.MultipleIndicatorsTrend')
 * $t('enum.E_WaveTabs.Cepstrum')
 * $t('enum.E_WaveTabs.AxisOrbit')
 */
export enum E_WaveTabs {
    TrendWave = 0,
    TimeDomainWave = 1,
    FrequencySpectrum = 2,
    Waterfall = 3,
    Temperature = 4,
    MultipleIndicatorsTrend = 5,
    /**
     * 倒谱图
     */
    Cepstrum = 6,
    /**
     *运行轨迹
     */
    AxisOrbit = 7,
}

@Component({
    name: 'WaveSpectrum',
    components: {
        DataRetrieval,
        TrendWaveForm,
        TimeDomainWaveform,
        FrequencySpectrum,
        Waterfall,
        Temperature,
        MultipleIndicatorsTrend,
        EigenvalueShow,
        Cepstrum,
    },
})
export class WaveSpectrumImpl extends Vue {
    get Tabs() {
        return [
            { id: E_WaveTabs.TrendWave, component: TrendWaveForm, },
            { id: E_WaveTabs.TimeDomainWave, component: TimeDomainWaveform },
            { id: E_WaveTabs.FrequencySpectrum, component: FrequencySpectrum },
            { id: E_WaveTabs.Waterfall, component: Waterfall },
            { id: E_WaveTabs.Temperature, component: Temperature },
            { id: E_WaveTabs.MultipleIndicatorsTrend, component: MultipleIndicatorsTrend },
            { id: E_WaveTabs.Cepstrum, component: Cepstrum },
            { id: E_WaveTabs.AxisOrbit, component: AxisOrbit },
        ]
    }

    tabIndex = 0 // 页签

    get ActiveTab() {
        return this.Tabs[this.tabIndex]
    }

    dialogRetrieval = false // 过滤    

    queryForm = makeQueryForm()

    get HasTitle() {
        return [
            E_WaveTabs.Cepstrum,
            E_WaveTabs.TrendWave,
            E_WaveTabs.AxisOrbit,
        ].includes(this.ActiveTab.id)
    }

    title = ''

    onUpdateTitle(title: string) {
        this.title = title
    }

    @Ref()
    tabRef

    // 搜索
    onSearch() {
        // 温度
        if (this.ActiveTab.id !== E_WaveTabs.Temperature) {
            if (isCheckQueryFailedAndReport(this, this.queryForm)) {
                return
            }
        }

        if (this.tabRef && this.tabRef.onSearch) {
            this.tabRef.onSearch()
        }
    }
    // 重置
    onReset() {
        this.queryForm = makeQueryForm()
        if (this.tabRef && this.tabRef.onReset) {
            this.tabRef.onReset()
        }
    }
    // 页签切换
    changeTab(index) {
        this.tabIndex = index
        this.title = ''
    }

    //#region 
    // 打开过滤
    openRetrieval() {
        this.dialogRetrieval = true;
    }
    selectedData: ISelectedData = null
    onConfromSelectMapping(mapping: IMappingItem, selectedData: ISelectedData) {
        this.dialogRetrieval = false
        this.queryForm.mappingId = mapping.value
        this.selectedData = selectedData
    }
    // 关闭过滤
    closeRetrieval(selectedData: ISelectedData) {
        this.dialogRetrieval = false
        this.selectedData = selectedData
    }
    //#endregion

    //#region 公司相关
    companyOptions: ICompanyItem[] = []
    fetchCompanies() {
        this.$api.company.getCompanyList().then(res => {
            this.companyOptions = res.data
        })
    }
    onCompnayChange(id: number) {
        this.areaOptions = []
        this.queryForm.areaId = null
        this.fetchArea(id)

    }
    //#endregion

    //#region 区域相关
    areaOptions: IAreaItem[] = []
    fetchArea(companyId: number) {
        if (isEmptyStringOrNil(companyId)) {
            return
        }
        this.$api.area.getAreaListByCompanyId(companyId).then(res => {
            this.areaOptions = res.data
        })
    }
    //#endregion




    created() {
        this.fetchCompanies()
        this.$api.wave.waveHaveDefault().then(res => {
            if (isFailed(res)) {
                return
            }
            const defaultConfig = res.data
            if (defaultConfig) {
                const {
                    areaId,
                    mappingId,
                    companyId,
                    equipmentId,
                    startTime,
                    endTime,
                } = defaultConfig
                if (_.isNil(mappingId)) {
                    return
                }
                this.selectedData = {
                    areaId,
                    mappingId,
                    companyId,
                    equipmentId,
                }
                this.queryForm.mappingId = mappingId
                this.queryForm.timeRange = [startTime, endTime]
                this.onSearch()
            }
        })
    }
}
