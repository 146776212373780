/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { extend } from "lodash";
import {
    ICoordinate,
    IOptionNumberItem,
    IPageData,
    IPageParams
} from "../types"
import { IModelGetData } from "./model.types";

/**
 * 设备等级
 * $t('enum.E_EquipmentLevel.A')
 * $t('enum.E_EquipmentLevel.B')
 * $t('enum.E_EquipmentLevel.C')
 */
export enum E_EquipmentLevel {
    A = 'A',
    B = 'B',
    C = 'C',
}

export type IGetEquipmentListByAreaIdData = IOptionNumberItem[]

export interface IEquipmentExtend {
    /**
     * 编辑时候如果modelId没变这个需要传入
     */
    id?: number
    /**
     * 名称
     */
    name: string
    /**
     * 皮带周长
     */
    beltGirth: number;
    /**
     * 皮带型号
     */
    beltType: number;
    /**
     * 叶片数量
     */
    bladesNum: number;
    /**
     * 从动轮直径
     */
    drivenWheelDiameter: number;
    /**
     * 主动轮直径
     */
    drivingWheelDiameter: number;
    /**
     * 模型ID
     */
    modelId: number;
    /**
     * 模型配置ID
     */
    modelConfigId: number
    /**
     * 固有频率
     */
    naturalFrequency: number;
    /**
     * 驱动端轴承库型号
     */
    driveBearingType: string;
    /**
     * 非驱动端轴承库型号
     */
    nonDriveBearingType: string;
    /**
     * 额定电流
     */
    ratedCurrent: number;
    /**
     * 额定功率
     */
    ratedPower: number;
    /**
     * 额定转速
     */
    ratedSpeed: number;
    /**
     * 齿比
     */
    toothRatio: number;
    gearRecord: IGearPairRecord[]
}

export interface IGearPairRecord {
    id?: number
    /**
     * 轴号
     */
    axleld?: number
    /**
     * 齿轮1齿数
     */
    firstNum: number
    /**
     * 齿轮1驱动端轴承型号
     */
    firstDrive: string
    /**
     * 齿轮1非驱动端轴承型号
     */
    firstNoDrive: string
    /**
     * 齿轮2齿数
     */
    secondNum: number
    /**
     * 齿轮2驱动端轴承型号
     */
    secondDrive: string,
    /**
     * 齿轮2非驱动端轴承型号
     */
    secondNoDrive: string
}

export interface IEquipmentCreateItem extends Partial<ICoordinate> {
    /**
     * 公司ID
     */
    companyId: number
    /**
     * 区域ID
     */
    areaId: number;
    /**
     * 设备等级
     */
    equipmentLevel: E_EquipmentLevel;
    /**
     * 设备类型ID
     */
    equipmentTypeId: number;
    extend: IEquipmentExtend[];

    /**
     * 维保时间
     */
    maintenanceTime: number;

    /**
     * 运行时间
     */
    runTime?: string

    /**
     * 模型ID
     */
    modelId: number;
    /**
     * 设备名称
     */
    name: string;
}

export interface IEquipmentEditItem extends IEquipmentCreateItem {
    id: number
}



export type IEquipmentCreateParams = IEquipmentCreateItem
export type IEquipmentEditParams = IEquipmentEditItem
export interface IEquipmentGetData extends IEquipmentEditItem {
    companyName: string
    areaName: string
    imgId: number
    imgUrl: string
}


export enum E_EquipmentTypeListType {
    Model = 0,
    Equipment = 1,
}

export interface IEquipmentTypeListParams {
    companyId: number
    type: E_EquipmentTypeListType
}

export interface IEquipmentTypeListItem extends IOptionNumberItem {
    typeCount: number
}

export type IEquipmentTypeListData = IEquipmentTypeListItem[]

export interface IEquipmentListItem extends ICoordinate {

    // 区域图片地址
    areaImgUrl: string

    companyId: number

    areaId: number;
    /**
     * 区域
     */
    areaName: string;
    createBy: null;
    createTime: string;
    delFlag: number;
    /**
     * 设备等级
     */
    equipmentLevel: string;
    /**
     * 设备名称
     */
    equipmentName: string;
    /**
     * 设备类型Id
     */
    equipmentTypeId: number;
    /**
     * 设备类型名称
     */
    equipmentTypeName: null;
    /**
     * 设备ID
     */
    id: number;
    /**
     * 维保时间
     */
    maintenanceTime: number;
    /**
     * 测点数量
     */
    mappingCount: number;
    /**
     * 模型ID
     */
    modelId: string;
    /**
     * 设备模型
     */
    modelName: null;
    /**
     * 设备名称
     */
    name: string;
    updateBy: null;
    updateTime: null;
}

export interface IEquipmentListParams extends IPageParams {
    areaId?: number
    companyId?: number
    equipmentTypeIdList?: number[]
}

export type IEquipmentListData = IPageData<IEquipmentListItem>



export interface IEquipmentTypeModelListItem {
    modelList: IModelGetData[]
}

export interface IEquipmentTypeCheckParams {
    companyId: number
    name: string
}

export interface IEquipmentImportParams {
    file: File
}

/**
 * 皮带
 */
export interface IBeltItem {
    beltGirth: string
    beltType: string
    drivenWheelDiameter: string
    drivingWheelDiameter: string
    equipmentName: string
}

/**
 * 电机、空压机、离心机、辊子类别
 */
export interface ICentrifugeCompressorCurrentRollerItem {
    driveBearingType: string
    equipmentName: string
    naturalFrequency: string
    nonDriveBearingType: string
    ratedCurrent: string
    ratedPower: string
    ratedSpeed: string
}

/**
 * 风机、水泵类别
 */
export interface IFanPumpItem {
    bladesNum: string
    driveBearingType: string
    equipmentName: string
    naturalFrequency: string
    nonDriveBearingType: string
    ratedPower: string
    ratedSpeed: string
}

/**
 * 齿轮箱
 */
export interface IGearItem {
    adrive: string
    anodrive: string
    anum: string
    bdrive: string
    bnodrive: string
    bnum: string
    cdrive: string
    cnodrive: string
    cnum: string
    ddrive: string
    dnodrive: string
    dnum: string
    edrive: string
    eenum: string
    enodrive: string
    equipmentName: string
    fdrive: string
    fnodrive: string
    fnum: string
    gdrive: string
    gnodrive: string
    gnum: string
    hdrive: string
    hnodrive: string
    hnum: string
    naturalFrequency: string
    ratedPower: string
    ratedSpeed: string
}

export interface IEquipmentImportItem {
    /**
     * 皮带
     */
    beltList: IBeltItem[]
    /**
     * 离心机
     */
    centrifugeList: ICentrifugeCompressorCurrentRollerItem[]
    /**
     * 空压机
     */
    compressorList: ICentrifugeCompressorCurrentRollerItem[]
    /**
     * 电机
     */
    currentList: ICentrifugeCompressorCurrentRollerItem[]
    /**
     * 风机
     */
    fanList: IFanPumpItem[]
    /**
     * 齿轮箱
     */
    gearList: IGearItem[]

    /**
     * 水泵
     */
    pumpList: IFanPumpItem[]
    /**
     * 辊子
     */
    rollerList: ICentrifugeCompressorCurrentRollerItem[]

    /**
     * 模型
     */
    modelList: number[]

    /**
     * 区域名称
     */
    areaName: string
    companyName: string
    equipmentId: number
    equipmentLevel: E_EquipmentLevel
    equipmentName: string
    equipmentType: string
    equipmentTypeId: number
    /**
     * 维保时间
     */
    maintenanceTime: string
    /**
     * 模型id
     */
    modelId: string
    /**
     * 设备名模型
     */
    modelName: string
    /**
     * 测点数量
     */
    pointNum: string
}

export type IEquipmentImportData = IEquipmentImportItem[]
export interface IEquipmentRepeatAddParams {

    repeatVOS: IEquipmentImportItem[]

}

export interface IEquipmentUploadImageParams {
    /**
     * 设备id
     */
    equipmentId: number
    /**
     * 图片ID
     */
    imgId: number
}

export interface IEquipmentGetMonitorImgParams {
    mappingId: number
}

export interface IEquipmentGetMonitorImgData {
    equipmentId: number
    imgId: number
    imgUrl: string
}

export interface IEquipmentSettingCoordinatesParams extends ICoordinate {
    equipmentId: number
}
