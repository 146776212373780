<template>
    <el-button type="info" icon="el-icon-refresh-left" size="small" v-bind="$attrs" v-on="$listeners">
        {{ $t('buttons.reset') }}
    </el-button>
</template>
<script>
export default {
    name: 'ButtonReset'
}
</script>
