/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Ref, Prop, } from 'vue-property-decorator'
import * as echarts from 'echarts'
import RadioOptions from "@/components/RadioOptions/index.vue"
import { IWaveSpectrumQuery } from '../types'
import { FrequencyTimerFilterImpl } from '../component/FrequencyTimerFilter/FrequencyTimerFilterImpl'
import FrequencyTimerFilter from '../component/FrequencyTimerFilter/FrequencyTimerFilter.vue'
import { ISamplingTimeData } from '@/api/moudules/wavefrom.types'
import { E_ChartTypeString } from '@/types'
import { ChartTypeString2Eigenvalue } from '@/constants/charts'
import 'echarts-gl'
import { EChartImpl } from '@/components/EChart/EChartImpl'
import { Toolbox } from '@/utils/echarts'
import { spectrum } from '@/charts/waveChartOption';
import { getPrimaryColor } from '@/utils/color'
import { formatTitle2 } from '@/view_helper/wave'
import { toFixed } from '@/utils/math'
import ChartPopupOptions from '@/components/ChartPopupOptions.vue'
import { isFailedAndReport } from '@/api/helper'
import { toSensorDirectionDisplay } from '@/charts/utils'
import { waterfall } from '@/charts/waterfall'

function makeQueryForm() {
    return {
        pageNum: 1,
        pageSize: 15,
    }
}

@Component({
    name: 'Waterfall',
    components: {
        RadioOptions,
        FrequencyTimerFilter,
        ChartPopupOptions,
    }
})
export class WaterfallImpl extends Vue {
    @Prop({
        type: Object,
    }) query: IWaveSpectrumQuery

    @Ref()
    frequencyTimerFilterRef: FrequencyTimerFilterImpl

    lastMappingId: number
    mappingDataTask: Promise<any> = null
    fetchMappingData(mappingId: number) {
        if (mappingId === this.lastMappingId) {
            return
        }
        this.lastMappingId = mappingId
        this.mappingDataTask = this.$api.onlineDetection.onlineDetectionMapping({
            mappingId: mappingId
        }).then(res => {
            if (isFailedAndReport(this, res)) {
                return
            }
            return res.data.mapping
        })
    }

    samplingTimes: ISamplingTimeData[] = []

    get SelectedWaveIds() {
        return _.map(this.samplingTimes, s => s.waveId)
    }

    onFrequencyTimerChange(value: ISamplingTimeData, checked: boolean) {
        this.fetchMappingData(value.mappingId)
        if (checked) {
            this.samplingTimes = [...this.samplingTimes, value]
        } else {
            this.samplingTimes = _.filter(this.samplingTimes, s => s.waveId !== value.waveId)
        }
        this.updateChartWaterFall()
    }

    get Charts() {
        let res = [
            E_ChartTypeString.ACCELERATION_FREQUENCY,
            E_ChartTypeString.VELOCITY_FREQUENCY,
        ]
        res = [
            ...res,
            E_ChartTypeString.DISPLACEMENT_FREQUENCY,
            E_ChartTypeString.ENVELOPE_SIGNAL,
        ]
        return res
    }

    queryForm = makeQueryForm()

    // 搜索
    onSearch() {
        if (this.frequencyTimerFilterRef) {
            this.frequencyTimerFilterRef.onQuery()
        }
    }

    onReset() {
        if (this.frequencyTimerFilterRef) {
            this.frequencyTimerFilterRef.onReset()
        }
        this.samplingTimes = []
        this.waveChartRef.chart.setOption({}, true, true)
        this.chartWaterFallRef.chart.setOption({}, true, true)
    }

    selectChartType = E_ChartTypeString.ACCELERATION_FREQUENCY

    get SelectChartEigenvalue() {
        return ChartTypeString2Eigenvalue[this.selectChartType]
    }

    chartLabel(chart: E_ChartTypeString) {
        return this.$enumTrans.ChartTypeString(chart)
    }

    showDialog = false

    // 图表类型切换
    openOptions($event: MouseEvent) {
        $event.stopPropagation()
        this.showDialog = true;
    }
    changeOption(value) {
        this.selectChartType = value
        this.showDialog = false
        this.updateChartWaterFall()
    }
    closeOption() {
        this.showDialog = false;
    }

    @Ref()
    chartWaterFallRef: EChartImpl

    async requestFnChartWaterFall(chart: echarts.ECharts) {
        const tasks = _.map(this.samplingTimes, t => {
            return this.$api.waveform.queryWaveform({
                waveId: t.waveId,
                chartType: this.selectChartType,
            })
        })
        const results = await Promise.all(tasks)
        if (_.some(results, r => r.code !== 200)) {
            this.$message({
                type: 'error',
                message: this.$t('errors.serverError') as string
            })
            return
        }
        const option = waterfall({
            vm: this,
            chart: this.selectChartType,
            samplingTimes: this.samplingTimes,
            datas: _.map(results, x => x.data)
        })
        console.log(option)
        chart.setOption(option, true);
    }

    updateChartWaterFall() {
        this.chartWaterFallRef.request()
    }

    @Ref()
    waveChartRef: EChartImpl

    selectWaveId = null

    waveChartTime = null
    waveChartTitle = null
    async requestFnWaveChart(chart: echarts.ECharts) {
        const params = {
            mappingId: this.query.mappingId,
            chartType: this.SelectChartEigenvalue,
            waveId: this.selectWaveId
        }
        const res = await this.$api.wave.waveDetail(params)
        const { code, data } = res;
        if (code != 200 || null == data) {
            this.$message({
                message: this.$t('errors.noData') as string,
                type: 'success'
            });
            return;
        }
        this.waveChartTime = data.headInfo.waveTime
        this.waveChartTitle = formatTitle2(data.title)

        const mappingData = await this.mappingDataTask
        let option = spectrum({
            vm: this,
            type: this.SelectChartEigenvalue as any,
            data: data.wave,
            interval: data.interval,
            xyz: toSensorDirectionDisplay(mappingData),
        })
        chart.setOption(option, true, true);
    }

    updateWaveChart() {
        this.waveChartRef.request()
    }

    mounted() {
        this.chartWaterFallRef.chart.on('click', {
            seriesType: 'line3D',
            componentType: "series",
        }, ev => {
            let waveId = ev.data[3]
            this.selectWaveId = waveId
            this.updateWaveChart()
        })
    }
}
