/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import VueRouter, { Route, RouteConfig, RouterOptions } from "vue-router"

const routeRepair: RouteConfig[] = [{
    path: '/inspectionRepairLib',
    name: 'inspectionRepairLib',
    component: () => import(
    /* webpackChunkName: "spotCheck_inspectionRepairLib" */ "./view/InspectionRepairLib/InspectionRepairLib.vue"
    )
}, {
    path: '/inspectionRepairLibAddEdit',
    name: 'inspectionRepairLibAddEdit',
    meta: {
        parentName: 'inspectionRepairLib',
    },
    component: () => import(
    /* webpackChunkName: "spotCheck_inspectionRepairLib" */ "./view/InspectionRepairLibAddEdit/InspectionRepairLibAddEdit.vue"
    )
}, {
    path: '/inspectionRepair',
    name: 'inspectionRepair',
    component: () => import(
    /* webpackChunkName: "spotCheck_inspectionRepair" */ "./view/InspectionRepair/InspectionRepair.vue"
    )
}, {
    path: '/inspectionRepairAddEdit',
    name: 'inspectionRepairAddEdit',
    meta: {
        parentName: 'inspectionRepair',
    },
    component: () => import(
    /* webpackChunkName: "spotCheck_inspectionRepairLib" */ "./view/InspectionRepairAddEdit/InspectionRepairAddEdit.vue"
    )
},]

const routeUpkeep: RouteConfig[] = [{
    path: '/inspectionUpkeepLib',
    name: 'inspectionUpkeepLib',
    component: () => import(
    /* webpackChunkName: "spotCheck_InspectionUpkeepLib" */ "./view/InspectionUpkeepLib/InspectionUpkeepLib.vue"
    ),
}, {
    path: '/inspectionUpkeepLibAddEdit',
    name: 'inspectionUpkeepLibAddEdit',
    meta: {
        parentName: "inspectionUpkeepLib"
    },
    component: () => import(
    /* webpackChunkName: "spotCheck_InspectionUpkeepLib" */ "./view/InspectionUpkeepLibAddEdit/InspectionUpkeepLibAddEdit.vue"
    ),
}, {
    path: '/inspectionUpkeep',
    name: 'inspectionUpkeep',
    component: () => import(
    /* webpackChunkName: "spotCheck_InspectionUpkeep" */ "./view/InspectionUpkeep/InspectionUpkeep.vue"
    ),
}, {
    path: '/inspectionUpkeepAddEdit',
    name: 'inspectionUpkeepAddEdit',
    meta: {
        parentName: 'inspectionUpkeep',
    },
    component: () => import(
    /* webpackChunkName: "spotCheck_InspectionUpkeep" */ "./view/InspectionUpkeepAddEdit/InspectionUpkeepAddEdit.vue"
    ),
},]

const routeDangers: RouteConfig[] = [{
    path: '/inspectionDanger',
    name: 'inspectionDanger',
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionDanger" */ "./view/InspectionDanger/InspectionDanger.vue"
    ),
}, {
    path: '/inspectionDangerAddEdit',
    name: 'inspectionDangerAddEdit',
    meta: {
        parentName: 'inspectionDanger',
    },
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionDanger" */ "./view/InspectionDangerAddEdit/InspectionDangerAddEdit.vue"
    ),
}, {
    path: '/inspectionDangerLib',
    name: 'inspectionDangerLib',
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionDangerLib" */ "./view/InspectionDangerLib/InspectionDangerLib.vue"
    ),
}, {
    path: '/inspectionDangerLibAddEdit',
    name: 'inspectionDangerLibAddEdit',
    meta: {
        parentName: 'inspectionDangerLib',
    },
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionDangerLib" */ "./view/InspectionDangerLibAddEdit/InspectionDangerLibAddEdit.vue"
    ),
},]

const routeArticles: RouteConfig[] = [{
    path: '/appArticles',
    name: 'appArticles',
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionArticle" */ "./view/InspectionArticle/InspectionArticle.vue"
    ),
}, {
    path: '/appArticleAddEdit',
    name: 'appArticleAddEdit',
    meta: {
        parentName: 'appArticleAddEdit',
    },
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionArticle" */ "./view/InspectionArticleAddEdit/InspectionArticleAddEdit.vue"
    ),
}, {
    path: '/appBanner',
    name: 'appBanner',
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionArticle" */ "./view/InspectionBanner/InspectionBanner.vue"
    ),
}, {
    path: '/appBrief',
    name: 'appBrief',
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionBriefAddEdit" */ "./view/InspectionBriefAddEdit/InspectionBriefAddEdit.vue"
    ),
}, {
    path: '/ask',
    name: 'ask',
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionAsk" */ "./view/InspectionAsk/InspectionAsk.vue"
    ),
},]

const routeWeekReport: RouteConfig[] = [{
    path: '/inspectWeekReport',
    name: 'inspectWeekReport',
    component: () => import(
        /* webpackChunkName: "spotCheck_InspectWeekReport" */ "./view/InspectionWeek/weeklyReport.vue"
    ),
}, {
    path: "/inspectweeklyReportCreateAndEdit",
    name: "inspectWeeklyReportCreateAndEdit",
    component: () =>
        import(
  /* webpackChunkName: "spotCheck_InspectWeekReport" */ "./view/InspectionWeek/weeklyReportCreateAndEdit/weeklyReportCreateAndEdit.vue"
        ),
    meta: {
        noMainLayoutComponent: true,
        keepDark: true,
    }
}]


export const routes: RouteConfig[] = [{
    path: '/inspectionTask',
    name: 'inspectionTask',
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionTask" */ "./view/InspectionTask/InspectionTask.vue"
    ),
}, {
    path: '/inspectionTaskAddEdit',
    name: 'inspectionTaskAddEdit',
    meta: {
        parentName: 'inspectionTask',
    },
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionTask" */ "./view/InspectionTaskAddEdit/InspectionTaskAddEdit.vue"
    ),
}, {
    path: '/inspectionTaskView',
    name: 'inspectionTaskView',
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionTask" */ "./view/InspectionTaskView/InspectionTaskView.vue"
    ),
}, {
    path: '/inspectionMapping',
    name: 'inspectionMapping',
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionMapping" */ "./view/InspectionMapping/InspectionMapping.vue"
    ),
}, {
    path: '/inspectionWave',
    name: 'inspectionWave',
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionWave" */ "./view/InspectionWave/InspectionWave.vue"
    ),
}, {
    path: '/inspectionStandard',
    name: 'inspectionStandard',
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionStandard" */ "./view/InspectionStandard/InspectionStandard.vue"
    ),
}, {
    path: '/inspectionGroup',
    name: 'inspectionGroup',
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionGroup" */ "./view/InspectionGroup/InspectionGroup.vue"
    ),
}, {
    path: '/inspectionGroupAddEdit',
    name: 'inspectionGroupAddEdit',
    meta: {
        parentName: 'inspectionGroup',
    },
    component: () => import(
      /* webpackChunkName: "spotCheck_InspectionGroupCreate" */ "./view/InspectionGroupAddEdit/InspectionGroupAddEdit.vue"
    ),
}, {
    path: '/inspectionPoint',
    name: 'inspectionPoint',
    component: () => import(
      /* webpackChunkName: "spotCheck_inspectionPoint" */ "./view/InspectionPoint/InspectionPoint.vue"
    ),
}, {
    path: '/inspectionPointAddEdit',
    name: 'inspectionPointAddEdit',
    meta: {
        parentName: 'inspectionPoint',
    },
    component: () => import(
      /* webpackChunkName: "spotCheck_inspectionPoint" */ "./view/InspectionPointAddEdit/InspectionPointAddEdit.vue"
    ),
},
{
    path: '/inspectionArea',
    name: 'inspectionArea',
    component: () => import(
      /* webpackChunkName: "spotCheck_inspectionArea" */ "./view/InspectionArea/InspectionArea.vue"
    ),
}, {
    path: '/inspectionAreaEdit',
    name: 'inspectionAreaEdit',
    meta: {
        parentName: 'inspectionArea',
    },
    component: () => import(
    /* webpackChunkName: "spotCheck_inspectionArea" */ "./view/InspectionAreaEdit/InspectionAreaEdit.vue"
    ),
}, {
    path: '/inspectionLine',
    name: 'inspectionLine',
    component: () => import(
    /* webpackChunkName: "spotCheck_inspectionLine" */ "./view/InspectionLine/InspectionLine.vue"
    ),
}, {
    path: '/inspectionLineAddEdit',
    name: 'inspectionLineAddEdit',
    meta: {
        parentName: 'inspectionLine',
    },
    component: () => import(
    /* webpackChunkName: "spotCheck_inspectionLine" */ "./view/InspectionLineAddEdit/InspectionLineAddEdit.vue"
    ),
},
...routeWeekReport,
...routeDangers,
...routeUpkeep,
...routeRepair,
...routeArticles,
]
