/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Ref, Prop, } from 'vue-property-decorator'

import { IWaveSpectrumQuery } from '../types';
import { IChartPanelOptions } from '../component/ChartPanel/ChartPanelImpl';
import { E_Eigenvalue } from '@/types';
import { MultipleChartImpl } from '../component/MultipleChart/MultipleChartImpl';
import MultipleChart from '../component/MultipleChart/MultipleChart.vue'

@Component({
    name: 'TimeDomainWaveForm',
    components: {
        MultipleChart,
    }
})
export class TimeDomainWaveFormImpl extends Vue {
    @Prop({
        type: Object,
    }) query: IWaveSpectrumQuery

    get chartPanelOptions() {
        let chartOptions = [
            { label: this.$t('charts.accelerationTimeDomain') as string, value: E_Eigenvalue.AccelerationTimeDomain },
            { label: this.$t('charts.speedTimeDomain') as string, value: E_Eigenvalue.VelocityDomain },
        ]
        chartOptions = [
            ...chartOptions,
            { label: this.$t('charts.displacementTimeDomain') as string, value: E_Eigenvalue.DisplacementTimeDomain },
        ]
        let res: IChartPanelOptions = {
            chartKind: this.$t('charts.timeDomain') as string,
            chartOptions,
        }
        return res
    }

    @Ref()
    multipChartRef: MultipleChartImpl

    onSearch() {
        this.multipChartRef.onSearch()
    }

    onReset() {
        this.multipChartRef.onReset()
    }
}
