/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import VueRouter from "vue-router";
import { ILoginData } from "@/api/moudules/login.types";
import { autoLogin } from "./global";

class TokenManager {
    static TOKEN_KEY = 'token'
    getValue() {
        return localStorage.getItem(TokenManager.TOKEN_KEY)
    }
    setValue(value: string) {
        localStorage.setItem(TokenManager.TOKEN_KEY, value)
    }
    clear() {
        localStorage.removeItem(TokenManager.TOKEN_KEY)
    }
}
export const tokenManager = new TokenManager()

export function markAuth(data: ILoginData) {
    tokenManager.setValue(data.token)
    localStorage.setItem('user', data.userName) // 保存用户到本地会话
}

export function cleanAuth() {
    tokenManager.clear()
    localStorage.removeItem('user') // 保存用户到本地会话
}

export function goToLogin(router: VueRouter) {
    cleanAuth()
    // 自动登录只需要重新刷新就可以获取token
    if (autoLogin) {
        console.log('自动登录获取token!')
        window.location.reload()
        return
    }
    router.replace({
        name: 'login'
    })
}
