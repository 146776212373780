/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Ref, Prop, } from 'vue-property-decorator'

import DataRetrieval from "@/components/DataRetrieval/DataRetrieval.vue";

import {
    Collapse,
} from '@/components/Collapse/index'
import { IWaveSpectrumQuery } from '../types';
import TemperatureChartPanel from '../component/TemperatureChartPanel/TemperatureChartPanel.vue'
import { ITemperatureChartPanelOptions } from '../component/TemperatureChartPanel/TemperatureChartPanelImpl';

@Component({
    name: 'Temperature',
    components: {
        DataRetrieval,
        Collapse,
        TemperatureChartPanel,
    }
})
export class TemperatureImpl extends Vue {
    @Prop({
        type: Object
    }) query: IWaveSpectrumQuery

    get OptionsAtlas(): ITemperatureChartPanelOptions {
        return {
            title: this.$t('wave.temperatureContrastChart') as string,
            request: this.$api.waveform.queryTemperatureAtlas
        }
    }

    get OptionsRiseAtlas(): ITemperatureChartPanelOptions {
        return {
            title: this.$t('wave.temperatureRiseComparisonChart') as string,
            request: this.$api.waveform.queryTemperatureRiseAtlas,
        }
    }
}
