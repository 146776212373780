/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Ref, Prop, } from 'vue-property-decorator'
import { IWaveSpectrumQuery } from '../types';
import { IChartPanelOptions } from '../component/ChartPanel/ChartPanelImpl';
import { E_Eigenvalue } from '@/types';
import MultipleChart from '../component/MultipleChart/MultipleChart.vue'
import { MultipleChartImpl } from '../component/MultipleChart/MultipleChartImpl';


@Component({
    name: 'FrequencySpectrum',
    components: {
        MultipleChart,
    }
})
export class FrequencySpectrumImpl extends Vue {
    @Prop({
        type: Object,
    }) query: IWaveSpectrumQuery

    get chartPanelOptions() {
        let opt = [
            { label: this.$t('charts.accelerationFrequencyDomain') as string, value: E_Eigenvalue.AccelerationFrequencyDomain },
            { label: this.$t('charts.velocityFrequencyDomain') as string, value: E_Eigenvalue.VelocityFrequencyDomain },
        ]
        opt = [
            ...opt,
            { label: this.$t('charts.displacementSpectrum') as string, value: E_Eigenvalue.DisplacementSpectrum },
            { label: this.$t('charts.envelopeSpectrum') as string, value: E_Eigenvalue.EnvelopeSpectrum },
        ]
        let res: IChartPanelOptions = {
            chartKind: this.$t('charts.frequencyDomain') as string,
            chartOptions: opt,
        }
        return res
    }

    @Ref()
    multipChartRef: MultipleChartImpl

    onSearch() {
        this.multipChartRef.onSearch()
    }

    onReset() {
        this.multipChartRef.onReset()
    }
}
