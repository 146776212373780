/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from "lodash";

export type Vec2 = [number, number]
export type Vec3 = [number, number, number]

export type T_WithSequnce<T> = T & { _sequence: number }

export function withSequence<T>(data: T[], start = 1) {
    return _.map(data, (item, idx) => {
        return {
            _sequence: idx + start,
            ...item,
        }
    })
}

/**
 * 矩阵转置 
 */
export function transpose<T>(arr: T[][]) {
    if (_.isEmpty(arr)) {
        return arr
    }
    const oldRows = arr.length
    const oldCols = arr[0].length
    const newRows = oldCols
    const newCols = oldRows
    const res = []
    for (let r = 0; r < newRows; r++) {
        let colArray = new Array(newCols)
        for (let c = 0; c < newCols; c++) {
            colArray[c] = arr[c][r]
        }
        res.push(colArray)
    }
    return res
}

export function toFixed(n, digits = 2) {
    let num = _.toNumber(n)
    if (!_.isFinite(num)) {
        return ''
    }
    return _.toNumber(n).toFixed(digits)
}


export function arrayWithFirstLast<T>(array: T[]) {
    const arrSize = _.size(array)
    return _.map(array, (item, idx) => {
        return {
            value: item,
            isFirst: idx === 0,
            isLast: idx === arrSize - 1
        }
    })
}

export function mapToPercents(data: number[]) {
    if (_.isEmpty(data)) {
        return []
    }
    const total = _.sum(data)
    if (total === 0) {
        return _.map(data, d => 0)
    }
    const initial = _.initial(data)
    const initialPercents = _.map(initial, num => {
        return _.toInteger(num * 100 / total || 0)
    })
    const lastPercent = 100 - _.sum(initialPercents)
    return [...initialPercents, lastPercent]
}
