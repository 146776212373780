import _ from 'lodash'
import { IEigenvalueInfo, IEigenvalueInfoGroup } from '@/api/moudules/wave.types'
import { T_SpectrumLegend } from '@/charts/waveChartOption'
import { eigenvalueValue } from '@/view_helper/eigenvalue'
import { Vue, Component, Inject, Watch, } from 'vue-property-decorator'

@Component
export class EigenvalueShowMixin extends Vue {
    selectLegend: T_SpectrumLegend    

    //#region 特征值展示    
    @Inject('updateEigenvalue')
    updateEigenvalue: (value: IEigenvalueInfo) => void

    @Inject('showEigenvalue')
    showEigenvalue: (visible: boolean) => void


    eigenvalue: IEigenvalueInfoGroup = {} as any
    onShowEigenvalue() {
        this.updateEigenvalue(this.Eigenvalue)
        this.showEigenvalue(true)
    }
    get HasEigenvalue() {
        return !_.isEmpty(this.Eigenvalue)
    }
    get Eigenvalue() {
        return eigenvalueValue(this.eigenvalue, this.selectLegend)
    }

    @Watch('Eigenvalue')
    onEigenvalue(value) {
        if (_.isEmpty(value)) {
            this.showEigenvalue(false)
        } else {
            this.updateEigenvalue(this.Eigenvalue)
        }
    }
    //#endregion           

    beforeDestroy() {
        this.showEigenvalue(false)
    }
}
