/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios"
import {
    IPlatformCompanySettingData,
    IPlatformCompanySettingParams,
    IPlatformCompanySettingSaveParams
} from "./platformCompany.types"

/**
 *  模板图片配置
 */
export function platformCompanyGetSetting(params: IPlatformCompanySettingParams) {
    return axios<IPlatformCompanySettingData>({
        method: 'get',
        url: '/platformCompany/setting/getPlatformCompany',
        params
    })
}

/**
 * 设置公司模板图片 
 */
export function platformCompanySettingSave(params: IPlatformCompanySettingSaveParams) {
    return axios({
        method: 'post',
        url: '/platformCompany/setting/save',
        data: params
    })
}
