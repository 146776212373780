<template>
    <div class="btn-back" v-bind="$attrs" v-on="$listeners">
        <svg t="1675835245078" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            width="200" height="200">
            <path
                d="M588.468659 257.265591H123.316451L371.227243 58.55359a31.947267 31.947267 0 1 0-39.614611-49.837737l-319.472671 255.578137v11.501016a30.669376 30.669376 0 0 0 0 4.472617v3.194727a30.669376 30.669376 0 0 0 0 4.472617v11.501016l319.472671 255.578137a31.947267 31.947267 0 1 0 40.253556-49.837737L123.316451 321.160125h465.152208C792.292223 321.160125 958.418011 464.283881 958.418011 640.632795s-166.125789 319.47267-369.949352 319.472671H95.841801a31.947267 31.947267 0 0 0 0 63.894534h492.626858C830.628943 1024 1022.312545 852.123703 1022.312545 640.632795s-191.683602-383.367205-433.843886-383.367204z">
            </path>
        </svg>
        <div class="btn-back__text">{{ $t('buttons.back') }}</div>
    </div>
</template>
<script>
export default {
    name: 'Back'
}
</script>
<style lang="scss" scoped>
.btn-back {
    display: flex;
    cursor: pointer;

    .icon {
        width: 16px;
        height: 16px;
        fill: var(--primary-color);
    }

    &__text {
        margin-left: 4px;
        line-height: 16px;
    }
}
</style>
