/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * 获取轴向颜色
 */
function getAxialColor(value) {
    if (value == "x") {
        return "rgba(108,228,67,1)";
    } else if (value == "y") {
        return "rgba(217,0,181,1)";
    } else if (value == "z") {
        return "rgba(255,138,65,1)";
    } else if (value == 'h') {
        return "rgba(155,102,165,1)";
    }
}

/**
 * 健康等级分数颜色
 */
function getHealthScoreColor(value) {
    if (value < 60) {
        return "rgba(255,114,111,1)";
    } else if (value >= 60 && value < 80) {
        return "rgba(255,167,65,1)";
    } else {
        return "rgba(41,210,144,1)";
    }
}

/**
 * 评分占比颜色
 */
function scoreRatioColor(value) {
    if (value == 1) {
        // 最严重
        return "rgba(254,57,53,1)";
    } else if (value == 2) {
        // 不怎么严重
        return "rgba(255,167,65,1)";
    } else {
        return "rgba(41,210,144,1)";
    }
}

export { getAxialColor, getHealthScoreColor, scoreRatioColor };


export interface IAlaramColor {
    normal: string
    offline: string
    c: string
    d: string
}

let cssVariable: IAlaramColor
export function getAlarmColorsFromCss() {
    if (cssVariable) {
        return cssVariable
    }
    let s = getComputedStyle(document.documentElement)
    cssVariable = {
        normal: s.getPropertyValue('--alarm-normal'),
        offline: s.getPropertyValue('--alarm-offline'),
        c: s.getPropertyValue('--alarm-c'),
        d: s.getPropertyValue('--alarm-d'),
    }
    return cssVariable
}

export function getVarColor(varName: string) {
    let s = getComputedStyle(document.documentElement)
    return s.getPropertyPriority(varName)
}


let primaryColor: string
export function getPrimaryColor() {
    if (primaryColor) {
        return primaryColor
    }
    let s = getComputedStyle(document.documentElement)
    return s.getPropertyValue('--primary-color')
}

let primaryInvertColor: string
export function getPrimaryInvertColor() {
    if (primaryInvertColor) {
        return primaryInvertColor
    }
    let s = getComputedStyle(document.documentElement)
    return s.getPropertyValue('--primary-color__invert')
}


export function getCheckStatusRadarColors() {
    let s = getComputedStyle(document.documentElement)
    return {
        axis: s.getPropertyValue('--check-status-radar__axis'),
        splitLine: s.getPropertyValue('--check-status-radar__splitLine'),
        areaColor: s.getPropertyValue('--check-status-radar__areaColor'),
        graphicFill: s.getPropertyValue('--check-status-radar__graphic_fill'),
        graphicStroke: s.getPropertyValue('--check-status-radar__graphic_stroke'),
        graphicTextTitle: s.getPropertyValue('--check-status-radar__graphic_text_title'),
        graphicTextScore: s.getPropertyValue('--check-status-radar__graphic_text_score'),
    }
}
