/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */
import _ from 'lodash'
import { E_Eigenvalue2 } from "@/api/moudules/eigenvalue.types";
import { IEigenvalueInfo, IEigenvalueInfoGroup } from "@/api/moudules/wave.types";
import { T_SpectrumLegend } from "@/charts/waveChartOption";

export const Eigenvalue2ValueItemKeyMap = Object.freeze({
    [E_Eigenvalue2.EffectiveValueOfAcceleration]: 'z1',
    [E_Eigenvalue2.MarginFactor]: 'z10',
    [E_Eigenvalue2.Variance]: 'z11',
    [E_Eigenvalue2.StandardDeviation]: 'z12',
    [E_Eigenvalue2.PulseFactor]: 'z13',
    [E_Eigenvalue2.VelocityEffectiveValue]: 'z14',
    [E_Eigenvalue2.PeakDisplacement]: 'z15',
    [E_Eigenvalue2.EnvelopeValue]: 'z16',
    [E_Eigenvalue2.PeakAcceleration]: 'z2',
    [E_Eigenvalue2.PeakValueOfAcceleration]: 'z4',
    [E_Eigenvalue2.Kurtosis]: 'z5',
    [E_Eigenvalue2.KurtosisIndex]: 'z6',
    [E_Eigenvalue2.Skewness]: 'z7',
    [E_Eigenvalue2.SkewIndex]: 'z8',
    [E_Eigenvalue2.CrestFactor]: 'z9',
})

export function eigenvalueValue(eigenvalueGroup: IEigenvalueInfoGroup, legend: T_SpectrumLegend) {
    return _.get(eigenvalueGroup, 'eigenvalue' + legend) as IEigenvalueInfo
}
