import { render, staticRenderFns } from "./retrieval.vue?vue&type=template&id=4801d4e2&scoped=true&"
var script = {}
import style0 from "./retrieval.vue?vue&type=style&index=0&id=4801d4e2&prod&lang=scss&scoped=true&"
import style1 from "./retrieval.vue?vue&type=style&index=1&id=4801d4e2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4801d4e2",
  null
  
)

export default component.exports