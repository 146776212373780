<template>
    <div class="wrap">
        <div class="no-data">{{ $t('errors.noData') }}</div>
    </div>
</template>
<script>
export default {
    name: "NoData",
}
</script>
<style scoped>
.wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-data {
    font-size: 16px;
}
</style>
