export enum E_Theme {
    Light = 'light',
    Dark = 'dark'
}

const THEME_KEY = '--theme'

export function getCurrentTheme(): E_Theme {
    return localStorage.getItem(THEME_KEY) as any || E_Theme.Dark
}

export function saveTheme(theme: E_Theme) {
    localStorage.setItem(THEME_KEY, theme)
}

export function themeInit() {
    let theme = getCurrentTheme()
    document.documentElement.classList.add(theme)
}

export function themeRemoveLight() {
    let theme = getCurrentTheme()
    document.documentElement.classList.remove('light')
}
