/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IPageData, IPageParams } from "../types"

/**
 * 参数
 */
export interface IFetchWaveParams {
    dataWaveId?: number
    mappingId: number
    chartType: number
}

export interface IFetchTrendData {
    aZ: number
    mappingId: number
    waveId: number
    x: string
    y1: number
    y2: number
    y3: number
}

export interface ITitle {
    areaName: string
    equipmentName: string
    pointName: string
    sensorId: string
}

/**
 * 结果
 */
export interface IFetchWaveData {
    /**
     * 特征值
     */
    eigenvalue: IEigenvalueInfoGroup
    /**
     * 分页偏移(0开始)
     */
    offset: number
    /**
     * 最后一个(包含)
     */
    end: number

    title: ITitle
    headInfo: any
    interval?: number
    wave: {
        x: number
        y1: number
        y2: number
        y3: number
    }[]
}

export interface IWaveListParams extends IPageParams {
    mappingId: number
    beginTime?: string
    endTime?: string
}

export interface IWaveListItem {
    id: number
    mappingId: number
    sensorID: string
    createTime: string
    // ... todo
}

export type IWaveListData = IPageData<IWaveListItem>

export interface IWaveDetailParams {
    pageNum?: number
    pageSize?: number

    sensorId?: string
    waveId: number
    chartType: number
    mappingId: number

    // chartType: 包络时候需要加入参数
    /**
     * 低通
     */
    k5?: number
    /**
     * 带通
     */
    k6?: number
    /**
     * 带通
     */
    k7?: number
}

export interface IWaveDetailData {
    /**
     * 特征值
     */
    eigenvalue: IEigenvalueInfoGroup
    /**
     * 分页偏移(0开始)
     */
    offset: number
    /**
     * 最后一个(包含)
     */
    end: number

    headInfo: {
        /// ...
        waveTime: string
        /**
         * 一共的波形点数
         */
        wavePoint: number
    }
    title: ITitle
    interval?: number
    wave: {
        x: number
        y1: number
        y2: number
        y3: number
    }[]
}


export interface IWaveHaveDefaultData {
    companyId: number
    areaId: number
    mappingId: number
    equipmentId: number
    startTime: string
    endTime: string
}
/**
 * 特征值展示
 */
export interface IEigenvalueInfo {
    /**
     * 有效值
     */
    z1: number
    /**
     * 峰值
     */
    z2: number
    /**
     * 峰峰值
     */
    z3: number
    /**
     * 平均幅值
     */
    z4: number
    /**
     * 峰值指标
     */
    z5: number
    /**
     * 裕度指标
     */
    z6: number
    /**
     * 歪度指标
     */
    z7: number
    /**
     * 峭度指标
     */
    z8: number
    /**
     * 脉冲指标
     */
    z9: number
    /**
     * 波形指标
     */
    z10: number
}

export interface IEigenvalueInfoGroup {
    eigenvalueX: IEigenvalueInfo
    eigenvalueY: IEigenvalueInfo
    eigenvalueZ: IEigenvalueInfo
}

export interface IWaveCepstrumParams {
    waveId: number
}

export interface IWaveCepstrumData {
    areaName: string
    equipmentName: string
    pointName: string
    sensorId: string
    x1: number[]
    y1: number[]
    y2: number[]
    y3: number[]
}

/**
 * 相位计算轴
 */
export enum E_PhaseAxis {
    X = 1,
    Y = 2,
    Z = 3,
}

export interface IWaveAxisOrbitParams {
    /**
     * 被减数
     */
    phaseAxis1: E_PhaseAxis
    /**
     * 减数
     */
    phaseAxis2: E_PhaseAxis

    /**
     * 转速
     */
    rotation?: number

    /**
     * 波形ID
     */
    waveId: number
}


export interface IWaveAxisOrbitData {
    areaName: string
    equipmentName: string
    /**
     * 轴心轨迹横坐标X_x间隔
     */
    interval: number
    /**
     * 相位差
     */
    phaseDiff: number
    pointName: number
    sensorId: string
    y1: number[]
    y2: number[]
    y3: number[]
}
