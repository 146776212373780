import { E_Eigenvalue } from "@/types";

export const TimeFrequencyDomainMap = Object.freeze({
    [E_Eigenvalue.AccelerationTimeDomain]: [E_Eigenvalue.AccelerationTimeDomain, E_Eigenvalue.AccelerationFrequencyDomain],
    [E_Eigenvalue.VelocityDomain]: [E_Eigenvalue.VelocityDomain, E_Eigenvalue.VelocityFrequencyDomain],
    [E_Eigenvalue.DisplacementTimeDomain]: [E_Eigenvalue.DisplacementTimeDomain, E_Eigenvalue.DisplacementSpectrum],
    [E_Eigenvalue.EnvelopeSpectrum]: [/*这里暂时没有包络时域*/E_Eigenvalue.EnvelopeSpectrum, E_Eigenvalue.EnvelopeSpectrum],
})

export function isNullFrequencyChart(chart: E_Eigenvalue) {
    return E_Eigenvalue.EnvelopeSpectrum === chart
}
