import { RouteConfig } from "vue-router";
import RouterProxyComponent from "../components/RouterProxyComponent.vue";

export enum SensorNetworkingRouteNames {
    /**
     * 设备列表
     */
    DeviceList = 'SensorNetworkingDeviceList',
    /**
     * 设备创建编辑
     */
    DeviceCreateEdit = 'SensorNetworkingDeviceCreateEdit',
    /**
     *  特征值
     */
    Eigenvalue = 'SensorNetworkingEigenvalue',
    /**
     *  波形
     */
    Wave = 'SensorNetworkingWave',
    /**
     * 图谱分析
     */
    WaveSpectrum = "SensorNetworkingWaveSpectrum",
    /**
     * 图谱分析（多时域）
     */
    WaveSpectrum__MultipleTimeDomain = "SensorNetworkingWaveSpectrum__MultipleTimeDomain",
    /**
     * 图谱分析(多频谱)
     */
    WaveSpectrum__MultiSpectrum = "SensorNetworkingWaveSpectrum__MultiSpectrum",
    /**
     * 报警记录
     */
    AlarmReport = "SensorNetworkingAlaramReport",
}

export const sensorNetworkingRouter: RouteConfig[] = [{
    path: "/sensorNetworking",
    name: "sensorNetworking",
    redirect: "configureManagementDefault",
    component: RouterProxyComponent,
    children: [
        {
            path: "deviceList",
            name: SensorNetworkingRouteNames.DeviceList,
            meta: {
                keepAlive: false,
                isBack: false,
            },
            component: () =>
                import(
                    /* webpackChunkName: "sensorNetworking" */ "../views/sensorNetworking/deviceList/deviceList.vue"
                ),
        },
        {
            path: "deviceCreateEdit",
            name: SensorNetworkingRouteNames.DeviceCreateEdit,
            meta: {
                keepAlive: false,
                isBack: false,
                parentName: SensorNetworkingRouteNames.DeviceList,
            },
            component: () =>
                import(
                    /* webpackChunkName: "sensorNetworking" */ "../views/sensorNetworking/deviceList/view/deviceCreateEdit.vue"
                ),
        },
        {
            path: "eigenvalue",
            name: SensorNetworkingRouteNames.Eigenvalue,
            meta: {
                keepAlive: false,
                isBack: false,
            },
            component: () =>
                import(
                    /* webpackChunkName: "sensorNetworking" */ "../views/sensorNetworking/eigenvalue.vue"
                ),
        },
        {
            path: "wave",
            name: SensorNetworkingRouteNames.Wave,
            meta: {
                keepAlive: false,
                isBack: false,
            },
            component: () =>
                import(
                    /* webpackChunkName: "sensorNetworking" */ "../views/sensorNetworking/wave.vue"
                ),
        },
        {
            path: "waveSpectrum",
            name: SensorNetworkingRouteNames.WaveSpectrum,
            meta: {
                keepAlive: false,
                isBack: false,
            },
            component: () =>
                import(
                    /* webpackChunkName: "sensorNetworking" */ "../views/sensorNetworking/waveSpectrum/waveSpectrum.vue"
                ),
            children: [
                {
                    path: 'MultiSpectrum',
                    name: SensorNetworkingRouteNames.WaveSpectrum__MultiSpectrum,
                    component: () => import(
                        /* webpackChunkName: "sensorNetworking" */ "../views/sensorNetworking/waveSpectrum/views/MultiSpectrum.vue"
                    )
                },
                {
                    path: 'MultipleTimeDomain',
                    name: SensorNetworkingRouteNames.WaveSpectrum__MultipleTimeDomain,
                    component: () => import(
                        /* webpackChunkName: "sensorNetworking" */ "../views/sensorNetworking/waveSpectrum/views/MultipleTimeDomain.vue"
                    )
                }
            ]
        },
        {
            path: "alarmReport",
            name: SensorNetworkingRouteNames.AlarmReport,
            meta: {
                keepAlive: false,
                isBack: false,
            },
            component: () =>
                import(
                    /* webpackChunkName: "sensorNetworking" */ "../views/sensorNetworking/alarmReport.vue"
                ),
        },
    ]
}]
