import { E_ChartType } from "@/utils/chart"
import { E_AlarmType } from "../enums"
import { IPageData, IPageParams } from "../types"
import { E_AlarmMode } from "./alarm.types"
import { E_Eigenvalue } from "@/types"

/**
 * 采集模式
 * $t('enum.E_SensorNetworkingCollType.Eigenvalue')
 * $t('enum.E_SensorNetworkingCollType.Wave')
 */
export enum E_SensorNetworkingCollType {
    /**
     * 特征值
     */
    Eigenvalue = 1,
    /**
     * 波形
     */
    Wave = 2,
}

/**
 * 
 */
export enum E_SensorNetworkingCollStart {
    StartCollect = 1,
    StopCollect = 2,
}

/**
 * 绑定状态
 */
export enum E_SensorNetworkingBindStatus {
    /**
     * 已绑定
     */
    HasBind = 1,
    /**
     * 已解绑
     */
    HasUnbind = 2,
}

export enum E_SensorNetworkingSelect {
    Selected = 1,
    Unselected = 2,
}

export interface ISensorNetworkingAlarmType {
    alarmType: E_AlarmType
    effectiveIntervalCount: number
    id?: number
    intervalCount: number
    intervalTime: number
    status: E_SensorNetworkingSelect
}

export interface ISensorNetWorkingAddItemVO {
    id?: number
    alarmThresholdId: number
    alarmTypeVOList: ISensorNetworkingAlarmType[]
    collFreq: number
    mappingName: string
    sensorId: string
    vthreshold: number
}

export interface ISensorNetworkingItem {
    /**
     * 测点图片地址
     */
    mappingImage: string
    /**
     * 公司id
     */
    companyId: number
    /**
     * 区域id
     */
    areaId: number
    /**
     * 设备id
     */
    equipmentId: number
    /**
     * 采集时长（秒）
     */
    collDuration: number
    /**
     * 
     */
    collStart: E_SensorNetworkingCollStart
    /**
     * 采集模式
     */
    collType: E_SensorNetworkingCollType
    /**
     * 
     */
    itemVOS: ISensorNetWorkingAddItemVO[]
    /**
    * 采集任务名称
    */
    collTaskName: string
}


export interface ISensorNetworkingAddParams {
    /**
     * 测点图片地址
     */
    mappingImage: string
    /**
     * 公司id
     */
    companyId: number
    /**
     * 区域id
     */
    areaId: number
    /**
     * 设备id
     */
    equipmentId: number
    /**
    * 采集任务名称
    */
    collTaskName: string
}

export interface ISensorNetworkingEditParams extends ISensorNetworkingAddParams {
    id: number
}

export interface ISensorNetworkingDeleteParams {
    id: number
}

export interface ISensorNetworkingDeleteMappingParams {
    id: number
}

export interface ISensorNetworkingMappingDetailParams {
    id: number
}

export interface ISensorNetworkingMappingDetailData extends ISensorNetworkingItem {
    id?: number
    mappingImageUrl: string
    networkingItemDtoList: ISensorNetWorkingAddItemVO[]
}

export type ISensorNetworkingPageListParams = IPageParams & Partial<{
    areaId
    collTaskName
    companyId
}>

export interface ISensorNetworkingPageItem {
    areaId: number
    areaName: string
    /**
     * 绑定状态
     */
    bindStatus: E_SensorNetworkingBindStatus
    collDuration: number
    collStart: number
    collStartTime: string
    collTaskName: string
    collType: E_SensorNetworkingCollType
    companyId: number
    companyName: string
    createdTime: string
    equipmentId: number
    equipmentName: string
    id: number
    mappingNum: string
    parentCompanyId: number
}

export type ISensorNetworkingPageListData = IPageData<ISensorNetworkingPageItem>

export interface ISensorNetworkingUnbindTaskParams {
    id: number
}

export type ISensorNetworkingMappingTreeParams = Partial<{
    companyId: number
    areaId: number
    equipmentId: number
    networdkingId: number
}>

export interface ITreeMappingDTO {
    mappingId: number
    mappingName: string
}

export interface ISensorNetworkingMappingTreeItem {
    id: number
    companyId: number
    companyName: string
    areaId: number
    areaName: string
    equipmentId: number
    collTaskName: string
    treeMappingDTOList: ITreeMappingDTO[]
}

export type ISensorNetworkingMappingTreeData = ISensorNetworkingMappingTreeItem[]


export interface ISensorNetworkingQueryMappingByEquipmentIdParams {
    equipmentId: number
}

export interface IMappingItem {
    mappingId: number
    mappingName: string
}

export type ISensorNetworkingQueryMappingByEquipmentIdData =
    IMappingItem[]


export type ISensorNetworkingAlaramListParams = IPageParams & Partial<{
    alarmLevelList: any[]
    alarmTypeList: any[]
    areaId: number
    companyId: number
    startTime: string
    endTime: string
    networkingId: number
    equipmentId: number
    mappingId: number
}>

export interface ISensorNetworkingAlaramItem {
    alarmAxis: string
    alarmLevel: number
    alarmMode: E_AlarmMode
    alarmType: E_AlarmType

    companyId: number
    companyName: string
    areaId: number
    areaName: string
    equipmentId: number
    equipmentName: string
    createTime: string
    mappingId: number
    mappingName: string

    kurtX: number
    kurtY: number
    kurtZ: number
    peakX: number
    peakY: number
    peakZ: number
    tem: number
    vx: number
    vy: number
    vz: number
    battery: number
}

export type ISensorNetworkingAlaramListData =
    IPageData<ISensorNetworkingAlaramItem>

export interface ISensorNetworkDataListByTimeParams {
    beginDate: string
    endDate: string
    mappingIds: number[]
    chartType: E_Eigenvalue
}

export interface ISensorNetworkDataListByTimeItem {
    mappingId: number
    mappingData: {
        aZ: number
        mappingId: number
        waveId: number
        x: string
        y: number
        y1: number
        y2: number
        y3: number
    }[]
}

export type ISensorNetworkDataListByTimeData =
    ISensorNetworkDataListByTimeItem[]

export type ISensorNetworkMappingWaveTimeParams = IPageParams & Partial<{
    startTime
    endTime
    mappingId
    mhfHz
}>


export interface ISensorNetworkMappingWaveTimeItem {
    waveId: number
    dateTime: string
    waveValue: any
}

export type ISensorNetworkMappingWaveTimeData =
    IPageData<ISensorNetworkMappingWaveTimeItem>

export type ISensorNetworkMappingByIdParams = IPageParams & Partial<{
    chartType
    mappingId
    sensorId
    waveId
}>

export interface ISensorNetworkMappingByIdData {
    data: any
    headInfo: any
    title: {
        areaName: string
        collTaskName: string
        companyName: string
        equipmentName: string
        id: number
        mappingName: string
        sensorId: string
    }
}

export interface ISensorNetworkingWaveParams {
    chartType: E_Eigenvalue
    mappingId: number
    waveId: number
    pageSize: number
    pageNum: number
}

export interface ISensorNetworkWaveTitle {
    areaName: string
    collTaskName: string
    companyName: string
    equipmentName: string
    id: number
    mappingName: string
    sensorId: string
}

export interface ISensorNetworkHeadInfo {
    battery: number
    currentValue: number
    mhfHz: string
    rotateSpeed: any
    runtime: number
    wavePoint: number
    waveTime: string
}

export interface ISensorNetworkWaveItem {
    interval: number
    offset: number
    end: number
    wave: {
        x: number
        y: number
        y1: number
        y2: number
        y3: number
    }[]
}

export interface ISensorNetworkWaveData {
    title: ISensorNetworkWaveTitle
    headInfo: ISensorNetworkHeadInfo
    data: ISensorNetworkWaveItem
}

export interface ISensorNetworkingGetThresholdCheckParams {
    mappingId: number
    sensorType: string
}

export interface ISensorNetworkingGetThresholdCheckData {
    configId
    fftPoints
    freqFactor
    ratedSpeed
    resolutionLevel
    sampleRate
    k1
    k2
    k3
    k4
    k5
    k6
    k7
    k11
    k12
    k13
    k14
    k15
    k16
}

export interface ISensorNetworkingWaveDiagnosisParams {

}

export interface ISensorNetworkingWaveExportParams {
    mappingId: number
    waveId: number
}

export interface ISensorNetworkingSaveMappingTaskParams {
    id: number
    /**
     * 采集时长（秒）
     */
    collDuration: number
    /**
     * 
     */
    collStart: E_SensorNetworkingCollStart
    /**
     * 采集模式
     */
    collType: E_SensorNetworkingCollType
    /**
     * 
     */
    itemVOS: ISensorNetWorkingAddItemVO[]
}


export interface ISensorNetworkingNetworkingMappingByEquipmentIdParams {
    equipmentId: number
}

export type ISensorNetworkingNetworkingMappingByEquipmentIdData = IMappingItem[]

export interface ISensorNetworkingTasksByEquipmentIdParams {
    equipmentId: number
}

export interface ITaskItem {
    collTaskName: string
    id: number
}

export type ISensorNetworkingTasksByEquipmentIdData = ITaskItem[]
