/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * 波形echarts
 */
import _ from 'lodash'
import { Toolbox } from "@/utils/echarts";
import { E_Eigenvalue } from '@/types';
import { formatDateTime } from '@/utils/datetime';
import { E_ChartType } from '@/utils/chart';
import { IThresholdGetSensorByMappingIdData } from '@/api/moudules/threshold.types';
import { groupToArrayBy, groupToArrayWith } from '@/utils/lang';
import { E_Theme } from '@/utils/theme';
import { getAxialColor, getPrimaryColor } from '@/utils/color';
import { ISensorNetworkDataListByTimeItem } from '@/api/moudules/sensorNetworking.types';
import { AxisSplitLine, LineConfig, LineSelectedConfig } from './consts';
import moment from 'moment';
import { InDebug } from '@/utils/env';
import { IXYZ, legendFormater } from './utils';
import { trendProcess } from './trendProcess';

export function trendBatch(vm: Vue, type: E_Eigenvalue, showTitle: boolean, mappingIdToName: Record<string, string>, data: ISensorNetworkDataListByTimeItem[], theme = E_Theme.Dark) {
    const isLight = theme === E_Theme.Light
    //是否又高频加速度
    let hasHightAcc = false
    let unitY = ""; // y轴单位
    let option = {}; // 返回值

    let dataset = _.map(data, item => {
        return {
            source: item.mappingData
        }
    })

    if (type == E_Eigenvalue.Acceleration) {
        // 加速度
        unitY = "(m/s²)";
    } else if (type == E_Eigenvalue.VibrationSeverity) {
        // 振动烈度
        unitY = "(mm/s)";
    } else if (type == E_Eigenvalue.Offset) {
        // 位移
        unitY = "(μm)";
    } else if (type == E_Eigenvalue.Temperature) {
        // 温度
        unitY = "(℃)";
    } else if (type == E_Eigenvalue.TemperatureIncrease) {
        // 温升
        unitY = "(℃)";
    } else if (type == E_Eigenvalue.Kurtosis) {
        // 峭度
        unitY = "";
    } else if (type == E_Eigenvalue.CrestFactor) {
        // 波峰因数
        unitY = "";
    } else if (type == E_Eigenvalue.EnvelopeDemodulation) {
        // 包络解调值
        unitY = "(m/s^2)";
    } else if (type == E_Eigenvalue.AxialDisplacement) {
        // 轴位移
        unitY = "(μm)";
    } /*else if (type == "频率") {
    // 频率
    unitY = "(Hz)";
  } else if (type == "信号强度") {
    // 信号强度
    unitY = "(dBm)";
  }*/ else if (type == E_Eigenvalue.RotationalSpeed) {
        // 转速
        unitY = "(r/min)";
    } else if (type == E_Eigenvalue.Angle) {
        // 倾角
        unitY = "(°)";
    } else if (type == E_Eigenvalue.HighFrequencyAcceleration) {
        // 高频Z轴加速度
    } else if (type == E_Eigenvalue.Battery) {
        // 电压
        unitY = "(V)";
    } else if (type === E_Eigenvalue.Ambienttem) {
        // 环境温度
        unitY = "(℃)";
    } else if (type === E_Eigenvalue.Rssi) {
        unitY = ''
    } else if (type === E_Eigenvalue.VFreq) {
        unitY = '(Hz)'
    }

    hasHightAcc = false
    // let dataGroups = groupToArrayBy(data, (item: any) => item.x)

    // data = _.map(dataGroups, items => {
    //     let item = items[0]
    //     return {
    //         ...item,
    //         x: formatDateTime(item.x),
    //         hasDuplicate: items.length > 1,
    //     }
    // })

    // series
    let series = [];
    let legend = null;
    let dimensions = []
    let currentColorIndex = 0
    let colors = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
    function nextColor() {
        return colors[currentColorIndex++ % colors.length]
    }
    const label = {
        formatter: (x) => {
            const {
                name,
                value,
                data,
            } = x
            const axis = data.axis
            if (axis) {
                return `${axis} -- ${name}: ${value}`
            }
            return `${name}: ${value}`
        },
        position: 'insideMiddleTop',
    }

    // 单轴
    if (
        type == E_Eigenvalue.Temperature ||
        type == E_Eigenvalue.TemperatureIncrease ||
        type == E_Eigenvalue.RotationalSpeed ||
        type == E_Eigenvalue.HighFrequencyAcceleration ||
        type == E_Eigenvalue.Ambienttem ||
        type == E_Eigenvalue.Battery ||
        type == E_Eigenvalue.Rssi
    ) {
        let markLine = {
            data: [],
            label,
        }
        dimensions = [{
            name: 'x',
        }, {
            name: 'y',
        }]
        series = _.flatMap(data, (item, idx) => [
            {
                datasetIndex: idx,
                name: vm.$enumTrans.Eigenvalue(type),
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y'
                },
                lineStyle: {
                    color: nextColor()
                },
                connectNulls: true,
                markLine,
                ...LineConfig,
            },
        ])
    } else {
        let markLineX = { data: [], label }
        let markLineY = { data: [], label }
        let markLineZ = { data: [], label }

        dimensions = [{
            name: 'x',
        }, {
            name: 'y1',
            displayName: 'X',
        }, {
            name: 'y2',
            displayName: 'Y',
        }, {
            name: 'y3',
            displayName: 'Z',
        }, ...hasHightAcc ? [{
            name: 'y4',
            displayName: 'H',
        }] : []]


        series = _.flatMap(data, (item, idx) => [
            {
                datasetIndex: idx,
                name: "X",
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y1'
                },
                lineStyle: {
                    color: nextColor()
                },
                connectNulls: true,
                ...LineConfig,
                // markLine: markLineX,
            },
            {
                datasetIndex: idx,
                name: "Y",
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y2'
                },
                lineStyle: {
                    color: nextColor()
                },
                connectNulls: true,
                ...LineConfig,
                // markLine: markLineY,
            },
            {
                datasetIndex: idx,
                name: "Z",
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y3'
                },
                lineStyle: {
                    color: nextColor()
                },
                connectNulls: true,
                ...LineConfig,
                // markLine: markLineZ,
            },
            ...hasHightAcc ? [{
                datasetIndex: idx,
                name: "H",
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y4'
                },
                lineStyle: {
                    color: nextColor()
                },
                connectNulls: true,
                ...LineConfig,
            }] : [],
        ])
        legend = {
            selectedMode: true,
            left: "center",
            top: "8px",
            data: ["X", "Y", "Z", ...hasHightAcc ? ['H'] : []],
        };
    }

    option = {
        tooltip: {
            trigger: "axis",
            formatter(params) {
                const {
                    axisValueLabel,
                    data,
                } = params[0]
                const title = axisValueLabel
                const items = _.map(params, param => {
                    const {
                        dimensionNames,
                        encode,
                        data,
                        seriesName,
                    } = param
                    const mappingId = data.mappingId
                    const mappingName = mappingIdToName[mappingId]
                    return `${mappingName} : ${seriesName} : ${data[dimensionNames[encode.y[0]]]}`
                })
                let displayData = [title, ...items]
                return _.join(displayData, '<br/>')
            },
        },
        dataset: dataset,
        grid: {
            top: "48px",
            left: "40px",
            right: "50px",
            bottom: "20px",
            containLabel: true,
        },
        legend: {
            ...legend,
            textStyle: {
                ...isLight ? {} : {
                    color: "rgb(255,255,255)",
                }
            },
            selectedMode: 'single'
        },
        toolbox: Toolbox,
        xAxis: {
            type: "time",
            formatter: '{yyyy}-{MM}-{dd} {HH}:{mm}',
            boundaryGap: false,
            axisLabel: {
            },
            axisLine: {
                lineStyle: {
                    ...isLight ? {} : {
                        color: "#fff",
                    }
                },
            },
            ...AxisSplitLine,
        },
        yAxis: {
            type: "value",
            name: vm.$enumTrans.Eigenvalue(type) + unitY,
            nameTextStyle: {
                align: 'left'
            },
            axisLine: {
                lineStyle: {
                    ...isLight ? {} : {
                        color: "#fff",
                    }
                },
            },
            ...AxisSplitLine,
        },
        dataZoom: [
            {
                type: "inside",
                start: 0,
                end: 100,
            },
            {
                type: "slider",
                start: 0,
                end: 100,
                height: 8,
                bottom: '10px',
            },
        ],
        series: series,
    };
    return option;
}

const markPointSymbolSize = InDebug ? 20 : 5

export interface ITrendOptions {
    vm: Vue
    type: E_Eigenvalue
    showTitle: boolean
    data
    dataGroups_?
    hasDataProcess?: boolean
    thresoldData?: IThresholdGetSensorByMappingIdData
    removeResend1MinData?: boolean
    theme?: E_Theme,
    axialDisplacementUnit?: string
    xyz?: IXYZ
    tools?: any
}

/***
 *  特征值
 *
 *  type：类型
 *  data：图表数据
 *  showTitle：是否标题
    removeResend1MinData: 是否删除近一分钟的数据
 *
 * ***/
// function trend(vm: Vue, type: E_Eigenvalue, showTitle: boolean, data, thresoldData?: IThresholdGetSensorByMappingIdData, removeResend1MinData = false, theme = E_Theme.Dark, axialDisplacementUnit = '') {
function trend({
    vm,
    type,
    showTitle,
    data,
    hasDataProcess,
    dataGroups_,
    thresoldData,
    tools,
    removeResend1MinData = false,
    theme = E_Theme.Dark,
    axialDisplacementUnit = '',
    xyz = {
        x: 'X',
        y: 'Y',
        z: 'Z'
    }
}: ITrendOptions) {
    const isLight = theme === E_Theme.Light
    //是否又高频加速度
    let hasHightAcc = false
    let unitY = ""; // y轴单位
    let option = {}; // 返回值

    if (type == E_Eigenvalue.Acceleration) {
        // 加速度
        unitY = "(m/s²)";
    } else if (type == E_Eigenvalue.VibrationSeverity) {
        // 振动烈度
        unitY = "(mm/s)";
    } else if (type == E_Eigenvalue.Offset) {
        // 位移
        unitY = "(μm)";
    } else if (type == E_Eigenvalue.Temperature) {
        // 温度
        unitY = "(℃)";
    } else if (type == E_Eigenvalue.TemperatureIncrease) {
        // 温升
        unitY = "(℃)";
    } else if (type == E_Eigenvalue.Kurtosis) {
        // 峭度
        unitY = "";
    } else if (type == E_Eigenvalue.CrestFactor) {
        // 波峰因数
        unitY = "";
    } else if (type == E_Eigenvalue.EnvelopeDemodulation) {
        // 包络解调值
        unitY = "(m/s^2)";
    } else if (type == E_Eigenvalue.AxialDisplacement) {
        // 轴位移        
        unitY = `(${axialDisplacementUnit || 'μm'})`;
    } /*else if (type == "频率") {
    // 频率
    unitY = "(Hz)";
  } else if (type == "信号强度") {
    // 信号强度
    unitY = "(dBm)";
  }*/ else if (type == E_Eigenvalue.RotationalSpeed) {
        // 转速
        unitY = "(r/min)";
    } else if (type == E_Eigenvalue.Angle) {
        // 倾角
        unitY = "(°)";
    } else if (type == E_Eigenvalue.HighFrequencyAcceleration) {
        // 高频Z轴加速度
    } else if (type == E_Eigenvalue.Battery) {
        // 电压
        unitY = "(V)";
    } else if (type === E_Eigenvalue.Ambienttem) {
        // 环境温度
        unitY = "(℃)";
    } else if (type === E_Eigenvalue.Rssi) {
        unitY = '(dBm)'
    } else if (type === E_Eigenvalue.VFreq) {
        unitY = '(Hz)'
    }

    hasHightAcc = type === E_Eigenvalue.Acceleration && data && data[0] && !_.isNil(data[0].y4)

    let dataGroups: any[][] = []
    if (hasDataProcess) {
        dataGroups = dataGroups_
    } else {
        let res = trendProcess(data, removeResend1MinData)
        data = res.data
        dataGroups = res.dataGroups

    }
    let markPointArr = _.flatMap(dataGroups, items => {
        if (items.length > 1) {
            let item = items[0]
            const {
                x, y, y1, y2, y3,
            } = item
            return [{
                xAxis: formatDateTime(x),
                yAxis: y || y1 || y2 || y3,
                itemStyle: {
                    ...isLight ? {} : {
                        color: '#f14343',
                    }
                }
            }]
        } else {
            return []
        }
    })
    let markPoint = {
        symbol: 'pin',
        symbolSize: markPointSymbolSize,
        data: markPointArr,
    }

    // series
    let series = [];
    let legend = null;
    let dimensions = []

    function fillMarkLineData(markLine, axis = '', c, d) {
        if (!_.isNil(c)) {
            markLine['data'].push({
                name: 'C', yAxis: c, axis,
            })
        }
        if (!_.isNil(d)) {
            markLine['data'].push({
                name: 'D', yAxis: d, axis,
            })
        }
    }
    const label = {
        formatter: (x) => {
            const {
                name,
                value,
                data,
            } = x
            let valueDisplay = value
            if (_.isNil(value)) {
                valueDisplay = '-'
            }
            const axis = data.axis
            if (axis) {
                return `${axis} -- ${name}: ${valueDisplay}`
            }
            return `${name}: ${valueDisplay}`
        },
        position: 'insideMiddleTop',
    }

    // 单轴
    if (
        type == E_Eigenvalue.Temperature ||
        type == E_Eigenvalue.TemperatureIncrease ||
        type == E_Eigenvalue.RotationalSpeed ||
        type == E_Eigenvalue.HighFrequencyAcceleration ||
        type == E_Eigenvalue.Ambienttem ||
        type == E_Eigenvalue.Battery ||
        type == E_Eigenvalue.Rssi ||
        type === E_Eigenvalue.AxialDisplacement
    ) {
        let markLine = {
            data: [],
            label,
        }
        if (thresoldData) {
            switch (type) {
                case E_Eigenvalue.Temperature:
                    fillMarkLineData(markLine, '', thresoldData.temCMax, thresoldData.temDMax)
                    break;
            }
        }
        dimensions = [{
            name: 'x',
        }, {
            name: 'y',
        }]
        series = [
            {
                name: vm.$enumTrans.Eigenvalue(type),
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y'
                },
                connectNulls: true,
                markLine,
                markPoint,
                ...LineSelectedConfig,
            },
        ];
    } else {
        let markLineX = { data: [], label }
        let markLineY = { data: [], label }
        let markLineZ = { data: [], label }
        if (thresoldData) {
            switch (type) {
                case E_Eigenvalue.Kurtosis:
                    fillMarkLineData(markLineX, 'X', thresoldData.kurtXCMax, thresoldData.kurtXDMax)
                    fillMarkLineData(markLineY, 'Y', thresoldData.kurtYCMax, thresoldData.kurtYDMax)
                    fillMarkLineData(markLineZ, 'Z', thresoldData.kurtZCMax, thresoldData.kurtZDMax)
                    break
                case E_Eigenvalue.CrestFactor:
                    fillMarkLineData(markLineX, 'X', thresoldData.peakXCMax, thresoldData.peakXDMax)
                    fillMarkLineData(markLineY, 'Y', thresoldData.peakYCMax, thresoldData.peakYDMax)
                    fillMarkLineData(markLineZ, 'Z', thresoldData.peakZCMax, thresoldData.peakZDMax)
                    break;
                case E_Eigenvalue.VibrationSeverity:
                    fillMarkLineData(markLineX, 'X', thresoldData.vXCMax, thresoldData.vXDMax)
                    fillMarkLineData(markLineY, 'Y', thresoldData.vYCMax, thresoldData.vYDMax)
                    fillMarkLineData(markLineZ, 'Z', thresoldData.vZCMax, thresoldData.vZDMax)
                    break
            }
        }

        dimensions = [{
            name: 'x',
        }, {
            name: 'y1',
            displayName: 'X',
        }, {
            name: 'y2',
            displayName: 'Y',
        }, {
            name: 'y3',
            displayName: 'Z',
        }, ...hasHightAcc ? [{
            name: 'y4',
            displayName: 'H',
        }] : []]
        series = [
            {
                name: 'X',
                displayName: '111',
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y1'
                },
                connectNulls: true,
                markLine: markLineX,
                markPoint,
                ...LineSelectedConfig,
            },
            {
                name: 'Y',
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y2'
                },
                connectNulls: true,
                markLine: markLineY,
                ...LineSelectedConfig,
            },
            {
                name: 'Z',
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y3'
                },
                connectNulls: true,
                markLine: markLineZ,
                ...LineSelectedConfig,
            },
            ...hasHightAcc ? [{
                name: "H",
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y4'
                },
                connectNulls: true,
                ...LineSelectedConfig,
            }] : [],
        ];
        legend = {
            formatter(param) {
                return legendFormater(param, xyz)
            },
            left: "center",
            top: "8px",
            textStyle: {
                ...isLight ? {} : {
                    color: "rgb(255,255,255)",
                }
            },
            data: ['X', 'Y', 'Z', ...hasHightAcc ? ['H'] : []],
        };
    }

    option = {
        tooltip: {
            trigger: "axis",
            snap: true,
            formatter(params) {
                const {
                    axisValueLabel,
                    data,
                } = params[0]
                const title = axisValueLabel
                const items = _.map(params, param => {
                    const {
                        dimensionNames,
                        encode,
                        data,
                        seriesName,
                    } = param
                    let valueDisplay = data[dimensionNames[encode.y[0]]]
                    if (_.isNil(valueDisplay)) {
                        valueDisplay = '-'
                    }
                    return seriesName + " : " + valueDisplay
                })
                let displayData = [title, ...items]
                if (data.hasDuplicate && InDebug) {
                    displayData = [...displayData, vm.$t('common.resendData')]
                }
                return _.join(displayData, '<br/>')
            },
        },
        color: [getAxialColor('x'), getAxialColor('y'), getAxialColor('z')],
        dataset: {
            dimensions: dimensions,
            source: data,
        },
        grid: {
            top: "48px",
            left: "40px",
            right: "50px",
            bottom: "20px",
            containLabel: true,
        },
        legend: legend,
        toolbox: {
            ...Toolbox,
            feature: {
                ...Toolbox.feature,
                ...tools,
            }
        },
        xAxis: {
            type: "time",
            formatter: '{yyyy}-{MM}-{dd} {HH}:{mm}',
            boundaryGap: false,
            ...AxisSplitLine,
            axisLine: {
                lineStyle: {
                    ...isLight ? {} : {
                        color: "#fff",
                    }
                },
            },
        },
        yAxis: {
            type: "value",
            name: vm.$enumTrans.Eigenvalue(type) + unitY,
            ...AxisSplitLine,
            nameTextStyle: {
                align: 'left'
            },
            // min: (value) => {
            //   return calMinY(value, type);
            // },
            // max: (value) => {
            //   return calMaxY(value, type);
            // },
            // interval: calIntervalY(type),
            axisLine: {
                lineStyle: {
                    ...isLight ? {} : {
                        color: "#fff",
                    }
                },
            },
        },
        dataZoom: [
            {
                type: "inside",
                start: 0,
                end: 100,
            },
            {
                type: "slider",
                start: 0,
                end: 100,
                height: 8,
                bottom: '10px',
            },
        ],

        series: series,
    };

    return option;
}

interface ISpectrumData {
    x: number
    y1: number
    y2: number
    y3: number
}

export type T_SpectrumLegend = 'X' | 'Y' | 'Z'

export interface ISpectrumOptions {
    vm: Vue
    type: E_Eigenvalue | E_ChartType
    data: ISpectrumData[]
    interval?: number
    tools?: any
    initLegend?: T_SpectrumLegend
    xyz?: IXYZ
}

/**
 * 波形频谱
 * @param {*} type
 * @param {*} title
 * @param {*} data
 * @returns
 */
function spectrum({
    vm,
    type,
    data,
    interval,
    tools,
    initLegend = 'Z',
    xyz = {
        x: 'X',
        y: 'Y',
        z: 'Z',
    },
}: ISpectrumOptions) {
    let unitX = ""; // x轴单位
    let unitY = ""; // y轴单位
    let option = {}; // 返回值

    // const { areaName, equipmentName, pointName, sensorId } = title;

    let ratioX = 1; // 系数

    if (type == E_Eigenvalue.VelocityDomain) {
        //速度时域
        unitY = "(mm/s)";
        // ratioX = 0.5;
        unitX = "ms";
    } else if (type == E_Eigenvalue.VelocityFrequencyDomain) {
        //速度频域
        unitY = "(mm/s)";
        // ratioX = 0.244140625; // 2000/8192
        unitX = "Hz";
    } else if (type == E_Eigenvalue.AccelerationFrequencyDomain) {
        //加速度频域
        unitY = "(m/s²)";
        // ratioX = 0.244140625; // 2000/8192
        unitX = "Hz";
    } else if (type == E_Eigenvalue.AccelerationTimeDomain) {
        //加速度时域
        unitY = "(m/s²)";
        unitX = "ms";
        // ratioX = 0.5;
    } else if (type == E_Eigenvalue.EnvelopeSpectrum) {
        //包络频谱
        unitY = "(m/s^2)";
        unitX = "Hz";
        // ratioX = 1;
    } else if (type == E_Eigenvalue.DisplacementTimeDomain) {
        //位移时域
        unitY = "(μm)";
        unitX = "ms";
        // ratioX = 1;
    } else if (type == E_Eigenvalue.DisplacementSpectrum) {
        //位移频谱
        unitY = "(μm)";
        unitX = "Hz";
        // ratioX = 1;
    }
    if (interval) {
        ratioX = interval
    }

    data = _.map(data, item => {
        return {
            ...item,
            x: ratioX * item.x,
        }
    })
    option = {
        animation: false,
        tooltip: {
            trigger: "axis",
            formatter(params) {
                let label = params[0].axisValueLabel + unitX
                let formatData = [
                    label,
                    ..._.map(params, item => {
                        let value = item.value[item.dimensionNames[item.encode.y[0]]]
                        return item.marker + item.seriesName + ":" + _.toNumber(value).toFixed(2)
                    })
                ]
                return _.join(formatData, '<br/>')
            },
        },
        color: [getAxialColor('x'), getAxialColor('y'), getAxialColor('z')],
        grid: {
            top: "48px",
            left: "24px",
            right: "30px",
            bottom: "20px",
            containLabel: true,
        },
        legend: {
            left: "center",
            top: "8px",
            formatter(param) {
                return legendFormater(param, xyz)
            },
            textStyle: {
                color: getPrimaryColor(),
            },
            selectedMode: 'single',
            selected: {
                X: initLegend === 'X',
                Y: initLegend === 'Y',
                Z: initLegend === 'Z',
            },
            data: ['X', 'Y', 'Z'],
        },
        toolbox: {
            ...Toolbox,
            feature: {
                ...Toolbox.feature,
                ...tools,
            }
        },
        dataset: {
            source: data,
        },
        xAxis: {
            // ...AxisSplitLine,
            splitLine: {
                show: false,
            },
            min: 'dataMin',
            max: _.ceil(_.last(data).x),
            type: "value",
            axisLabel: {
                formatter: '{value} ' + unitX,
            },
            axisLine: {
                lineStyle: {
                    color: getPrimaryColor(),
                },
            },
        },
        yAxis: {
            ...AxisSplitLine,
            type: "value",
            name: vm.$enumTrans.Eigenvalue(type as any) + unitY,
            nameTextStyle: {
                align: 'left',
            },
            axisLine: {
                lineStyle: {
                    color: getPrimaryColor(),
                },
            },
        },
        dataZoom: [
            {
                type: "inside",
                start: 0,
                end: 100,
            },
            {
                type: "slider",
                start: 0,
                end: 100,
                height: 8,
                bottom: '10px'
            },
        ],
        series: [
            {

                name: 'X',
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y1',
                },
                ...LineConfig,
            },
            {

                name: 'Y',
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y2',
                },
                ...LineConfig,
            },
            {
                name: 'Z',
                type: "line",
                encode: {
                    x: 'x',
                    y: 'y3',
                },
                ...LineConfig,
            },
        ],
    };
    return option;
}

// 计算Y轴最小值
function calMinY(value, type: E_Eigenvalue) {
    if (type == E_Eigenvalue.Acceleration) {
        // 加速度
        return null;
    } else if (type == E_Eigenvalue.VibrationSeverity) {
        // 振动烈度
        return null;
    } else if (type == E_Eigenvalue.Offset) {
        // 位移
        return null;
    } else if (type == E_Eigenvalue.Temperature) {
        // 温度
        return 0;
    } else if (type == E_Eigenvalue.TemperatureIncrease) {
        // 温升
        return 0;
    } else if (type == E_Eigenvalue.Kurtosis) {
        // 峭度
        return null;
    } else if (type == E_Eigenvalue.CrestFactor) {
        // 波峰因数
        return null;
    } else if (type == E_Eigenvalue.EnvelopeDemodulation) {
        // 包络解调值
        return null;
    }
    return null;
}
// 计算Y轴最大值
function calMaxY(value, type: E_Eigenvalue) {
    if (type == E_Eigenvalue.Acceleration) {
        // 加速度
        if (value.max < 0.1) {
            return 0.1;
        }
        if (value.max > 100) {
            return 100;
        }
        return value.max;
    } else if (type == E_Eigenvalue.VibrationSeverity) {
        // 振动烈度
        if (value.max < 0.5) {
            return 0.5;
        }
        if (value.max > 100) {
            return 100;
        }
        return value.max;
    } else if (type == E_Eigenvalue.Offset) {
        // 位移
        // 先放开
        // if (value.max < 10) {
        //   return 10;
        // }
        // if (value.max > 500) {
        //   return 500;
        // }
        return value.max;
    } else if (type == E_Eigenvalue.Temperature) {
        // 温度
        return 200;
    } else if (type == E_Eigenvalue.TemperatureIncrease) {
        // 温升
        return 200;
    } else if (type == E_Eigenvalue.Kurtosis) {
        // 峭度
        if (value.max < 1) {
            return 1;
        }
        if (value.max > 1000) {
            return 1000;
        }
        return value.max;
    } else if (type == E_Eigenvalue.CrestFactor) {
        // 波峰因数
        if (value.max < 1) {
            return 1;
        }
        if (value.max > 1000) {
            return 1000;
        }
        return value.max;
    } else if (type == E_Eigenvalue.EnvelopeDemodulation) {
        // 包络解调值
        if (value.max < 0.1) {
            return 0.1;
        }
        if (value.max > 50) {
            return 50;
        }
        return value.max;
    }
    return value.max;
}
// 计算Y轴的刻度
function calIntervalY(type: E_Eigenvalue) {
    if (type == E_Eigenvalue.Acceleration) {
        // 加速度
        return null;
    } else if (type == E_Eigenvalue.VibrationSeverity) {
        // 振动烈度
        return null;
    } else if (type == E_Eigenvalue.Offset) {
        // 位移
        return null;
    } else if (type == E_Eigenvalue.Temperature) {
        // 温度
        return 50;
    } else if (type == E_Eigenvalue.TemperatureIncrease) {
        // 温升
        return 50;
    } else if (type == E_Eigenvalue.Kurtosis) {
        // 峭度
        return null;
    } else if (type == E_Eigenvalue.CrestFactor) {
        // 波峰因数
        return null;
    } else if (type == E_Eigenvalue.EnvelopeDemodulation) {
        // 包络解调值
        return null;
    }
    return null;
}

/***
 *  温度温升
 *  '
 *
 * ***/
function temperature(vm: Vue, title, legend, xData, series) {
    const option = {
        title: {
            text: title,
            left: "44.5%",
            top: "5%",
            textStyle: {
                color: getPrimaryColor(),
                size: "20px",
            },
        },
        tooltip: {
            trigger: "axis",
            // formatter(params) {
            //   var relVal = params[0].name;
            //   for (var i = 0; i < params.length; i++) {
            //     relVal +=
            //       "<br/>" +
            //       params[i].marker +
            //       params[i].seriesName +
            //       "：" +
            //       Number(params[i].value).toFixed(2);
            //   }
            //   return relVal;
            // },
        },
        legend: {
            width: "32%",
            right: "10%",
            textStyle: {
                color: getPrimaryColor(),
            },
            // selector: ["all", "inverse"],
            // selectorLabel: {
            //   color: "rgb(255,255,255)",
            // },
            // selectorPosition: "start",
            data: legend,
        },
        grid: {
            top: "48px",
            left: "40px",
            right: "40px",
            bottom: "20px",
            containLabel: true,
        },
        toolbox: Toolbox,
        xAxis: {
            type: "time",
            boundaryGap: false,
            // data: xData,
            axisLine: {
                lineStyle: {
                    color: getPrimaryColor(),
                },
            },
            ...AxisSplitLine,
        },
        yAxis: {
            type: "value",
            name: vm.$t('charts.temperature'),
            axisLine: {
                lineStyle: {
                    color: getPrimaryColor(),
                },
            },
            ...AxisSplitLine,
        },
        dataZoom: [
            {
                type: "inside",
                start: 0,
                end: 100,
            },
            {
                type: "slider",
                start: 0,
                end: 100,
                bottom: '10px',
            },
        ],
        series: series,
    };

    return option;
}

export { trend, spectrum, temperature };
