// 运行轨迹图
import _ from 'lodash'
import { getAxialColor, getPrimaryColor } from "@/utils/color";
import Vue from "vue";
import { AxisSplitLine, LineConfig } from "./consts";
import { Toolbox } from "@/utils/echarts";
import { toFixed } from '@/utils/math';
import { IXYZ } from './utils';

export interface IAxisOrbitSingleConfig {
    vm: Vue
    interval: number
    data: number[]
    key: string
}

export function axisOrbitSingle({
    vm,
    interval,
    data,
    key,
}: IAxisOrbitSingleConfig) {
    let unitX = 'ms'
    let unitY = "(m/s²)"
    let option: echarts.EChartsOption = {
        tooltip: {
            trigger: 'axis',
            valueFormatter(value) {
                return toFixed(value, 3) + 'ms'
            },
        },
        grid: {
            left: 50,
            right: 50,
        },
        color: [getAxialColor(key)],
        toolbox: Toolbox as any,
        dataset: {
            source: [
                _.map(data, (value, idx) => idx * interval),
                data
            ],
            seriesLayoutBy: 'row',
            dimensions: ['x', 'y']
        },
        xAxis: {
            ...AxisSplitLine as any,
            type: 'value',
            axisLabel: {
                formatter: '{value} ' + unitX,
            },
            axisLine: {
                lineStyle: {
                    color: getPrimaryColor(),
                },
            },
        },
        yAxis: {
            ...AxisSplitLine as any,
            type: "value",
            name: vm.$t('management.amplitude') + unitY,
            nameTextStyle: {
                align: 'left',
            },
            axisLine: {
                lineStyle: {
                    color: getPrimaryColor(),
                },
            },
        },
        dataZoom: [
            {
                type: "inside",
            },
            {
                type: "slider",
            },
        ],
        series: [
            {
                type: 'line',
                encode: {
                    x: 'x',
                    y: 'y',
                },
                ...LineConfig,
            },
        ]
    }
    return option
}

export enum E_AxisOrbitSelect {
    XYZ = 'XYZ',
    XY = 'XY',
    XZ = 'XZ',
    YZ = 'YZ'
}



export interface IAxisOrbitConfig {
    vm: Vue
    select: E_AxisOrbitSelect
    x: number[]
    y: number[]
    z: number[]
    xyz: IXYZ
}

function axisOrbit3D({
    vm,
    x,
    y,
    z,
    xyz,
}: IAxisOrbitConfig) {
    let unit = "(m/s²)"
    return {
        toolbox: Toolbox,
        tooltip: {
            trigger: 'axis',
        },
        xAxis3D: {
            type: 'value',
            name: xyz.x + vm.$t('management.amplitude') + unit,
            nameTextStyle: {
                color: getPrimaryColor(),
            },
            axisLabel: {
                color: getPrimaryColor(),
            }
        },
        yAxis3D: {
            type: 'value',
            name: xyz.y + vm.$t('management.amplitude') + unit,
            nameTextStyle: {
                color: getPrimaryColor(),
            },
            axisLabel: {
                color: getPrimaryColor(),
            }
        },
        zAxis3D: {
            type: 'value',
            name: xyz.z + vm.$t('management.amplitude') + unit,
            nameTextStyle: {
                color: getPrimaryColor(),
            },
            axisLabel: {
                color: getPrimaryColor(),
            }
        },
        grid3D: {
            boxWidth: 100,//三维场景高度
            boxHeight: 100,//三维场景高度
            boxDepth: 100,//三维笛卡尔坐标系组件在三维场景中的深度
            viewControl: {
                projection: 'orthographic',
                // rotateSensitivity: 0,
                beta: 5,
                alpha: 30
            }
        },
        series: {
            type: 'line3D',
            coordinateSystem: 'cartesian3D',
            data: _.zip(x, y, z)
        }
    }
}

function axisOrbitPair({
    vm,
    select,
    x,
    y,
    z,
    xyz,
}: IAxisOrbitConfig) {
    let unit = "(m/s²)"
    let dataMap = {
        [E_AxisOrbitSelect.XY]: {
            dataA: x,
            dataB: y,
            nameA: 'X',
            nameB: 'Y',
            labelA: xyz.x,
            labelB: xyz.y
        },
        [E_AxisOrbitSelect.XZ]: {
            dataA: x,
            dataB: z,
            nameA: 'X',
            nameB: 'Z',
            labelA: xyz.x,
            labelB: xyz.z,
        },
        [E_AxisOrbitSelect.YZ]: {
            dataA: y,
            dataB: z,
            nameA: 'Y',
            nameB: 'Z',
            labelA: xyz.y,
            labelB: xyz.z,
        },
    }
    const data = dataMap[select]
    if (!data) {
        throw new Error('invalid select:' + select)
    }
    const {
        dataA,
        dataB,
        labelA,
        labelB,
        nameA,
        nameB,
    } = data

    return {
        toolbox: Toolbox,
        grid: {
            left: 120,
            right: 120,
        },
        tooltip: {
            trigger: 'axis',
            valueFormatter(value) {
                return toFixed(value, 3) + 'ms'
            },
        },
        dataset: {
            source: [
                dataA,
                dataB,
            ],
            seriesLayoutBy: 'row',
            dimensions: ['x', 'y']
        },
        xAxis: {
            ...AxisSplitLine as any,
            type: 'value',
            name: labelA + vm.$t('management.amplitude') + unit,
            axisLabel: {
                formatter: '{value} ' + unit,
            },
            axisLine: {
                lineStyle: {
                    color: getPrimaryColor(),
                },
            },
        },
        yAxis: {
            ...AxisSplitLine as any,
            type: "value",
            name: labelB + vm.$t('management.amplitude') + unit,
            nameTextStyle: {
                align: 'left',
            },
            axisLabel: {
                formatter: '{value} ' + unit,
            },
            axisLine: {
                lineStyle: {
                    color: getPrimaryColor(),
                },
            },
        },
        dataZoom: [
            {
                show: true,
                type: 'inside',
                filterMode: 'none',
                xAxisIndex: [0],
                startValue: -20,
                endValue: 20
            },
            {
                show: true,
                type: 'inside',
                filterMode: 'none',
                yAxisIndex: [0],
                startValue: -20,
                endValue: 20
            }
        ],
        series: {
            type: 'line',
            encode: {
                x: 'x',
                y: 'y'
            },
            ...LineConfig,
        }
    }
}


export function axisOrbit({
    vm,
    select,
    x,
    y,
    z,
    xyz,
}: IAxisOrbitConfig) {
    if (select === E_AxisOrbitSelect.XYZ) {
        return axisOrbit3D({
            vm,
            select,
            x, y, z,
            xyz,
        })
    } else {
        return axisOrbitPair({
            vm,
            select,
            x, y, z,
            xyz,
        })
    }
}