import { E_ChartTypeString } from "@/types";
import { getPrimaryColor } from "@/utils/color";
import { Toolbox } from "@/utils/echarts";
import { toFixed } from "@/utils/math";
import _ from 'lodash'
import Vue from "vue";

// 瀑布图
export interface IWaterfallConfig {
    vm: Vue
    chart: E_ChartTypeString
    samplingTimes: any[]
    datas: any[]
}

export function waterfall({
    vm,
    chart,
    samplingTimes,
    datas
}: IWaterfallConfig) {
    let series = _.flatMap(_.zip(datas, samplingTimes), ([item, time]) => {
        const {
            dateTime,
            waveList,
            interval,
        } = item
        const radioX = interval ? interval : 1
        return [{
            type: 'line3D',
            lineStyle: {
                width: 4,
            },
            name: 'X',
            data: _.map(waveList, wave => [radioX * wave.x, dateTime, wave.y1, time.waveId]),
        }, {
            type: 'line3D',
            lineStyle: {
                width: 4,
            },
            name: 'Y',
            data: _.map(waveList, wave => [radioX * wave.x, dateTime, wave.y2, time.waveId]),
        }, {
            type: 'line3D',
            lineStyle: {
                width: 4,
            },
            name: 'Z',
            data: _.map(waveList, wave => [radioX * wave.x, dateTime, wave.y3, time.waveId]),
        },]
    })
    let unitZ = "";
    switch (chart) {
        case E_ChartTypeString.ACCELERATION_FREQUENCY:
            unitZ = '(m/s²)';
            break
        case E_ChartTypeString.VELOCITY_FREQUENCY:
            unitZ = '(mm/s)'
            break
        case E_ChartTypeString.DISPLACEMENT_FREQUENCY:
            unitZ = '(μm)'
            break
        case E_ChartTypeString.ENVELOPE_SIGNAL:
            unitZ = '(m/s^2)'
            break
    }
    let option = {
        toolbox: Toolbox,
        tooltip: {
            formatter(params) {
                const {
                    value,
                    encode,
                } = params
                const x = toFixed(params.value[encode.x[0]], 2)
                const date = value[encode.y[0]]
                const res = toFixed(value[encode.z[0]], 2)
                return params.marker + params.seriesName + ' ' + String(res) +
                    `<br/> date ${date} <br/> x   ${x}`
            },
        },
        legend: {
            right: "200px",
            textStyle: {
                color: getPrimaryColor(),
            },
        },
        xAxis3D: {
            type: 'value',
            name: '',
            nameTextStyle: {
                color: 'white',
            },
            axisLabel: {
                color: getPrimaryColor(),
            }
        },
        yAxis3D: {
            type: 'category',
            name: '',
            nameTextStyle: {
                color: 'white',
            },
            axisLabel: {
                color: getPrimaryColor(),
            }
        },
        zAxis3D: {
            type: 'value',
            name: '',
            nameTextStyle: {
                color: 'white',
            },
            axisLabel: {
                color: getPrimaryColor(),
                formatter(x) {
                    return x + unitZ
                }
            }
        },
        grid3D: {
            boxWidth: 500,//三维场景高度
            boxHeight: 100,//三维场景高度
            boxDepth: 80,//三维笛卡尔坐标系组件在三维场景中的深度
            viewControl: {
                projection: 'orthographic',
                // rotateSensitivity: 0,
                beta: 5,
                alpha: 30
            }
        },
        series: series,
    }
    return option
}
