/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { E_Status, E_StatusString } from "@/types";
import { E_YesNoInverse, E_YesNoNumber, IPageData, IPageParams } from "../types";
import { E_Sex } from "./system";
import _ from "lodash";
import { E_AppUpdateType } from "./appver.types";

/**
 * 数据范围
 */
export enum E_DataScope {
    /**
     * 所有数据权限
     */
    All = '1',
    /**
     * 自定义数据权限
     */
    Custom = '2',
    /**
     * 本部门数据权限
     */
    CurrentDepartment = '3',
    /**
     * 本部门及以下数据权限
     */
    CurrentDepartmentAndBelow = '4',
    /**
     * 仅本人数据权限
     */
    Self = '5',
}

/**
 * 是否推送消息
 * $t('enum.E_PushAppMessage.Close')
 * $t('enum.E_PushAppMessage.Open')
 */
export enum E_PushAppMessage {
    /**
     * 关
     */
    Close = 0,
    /**
     * 开
     */
    Open = 1,
}


export interface IUserListParams extends IPageParams {
    areaId?: number
    companyId?: string
    startTime?: string
    endTime?: string
    phone?: string
    parentCompanyId?: string
    status?: E_Status
    userName?: string
}

export interface IUserListItem {
    appMessage: E_PushAppMessage
    areaId: number
    areaName: string
    avatar: ''
    companyId: number
    companyName: string
    email: string
    id: number
    userName: string
    nickName: string
    remark: string
    sex: E_Sex
    password: string
    originalPassword: string
    phone: string
    status: E_StatusString
    /**
     * 是否是公司管理员
     */
    superId: E_YesNoNumber
    /**
     * 是否是超级管理员
     */
    adminFlag: E_YesNoNumber
}
export type IUserListData = IPageData<IUserListItem>

export interface IUserMotifyPasswordParams {
    newPassword: string
    oriPassword: string
}

export interface ICurrentUser {
    adminFlag: E_YesNoNumber
    areaId: number
    areaName: string
    avatar: string
    companyId: number
    companyName: string
    email: string
    id: number
    /**
     * 最后登录时间
     */
    loginData: string
    loginIp: string
    nickName: string
    parentCompanyId: number
    password: string
    phone: string
    remark: string
    roleIds: number[]
    roles: string[]
    sex: E_Sex
    status: E_StatusString
    /**
     * 1. 是否总公司管理员
     */
    superId: E_YesNoNumber
    userName: string

    /**
     * 展示头像
     */
    logoId?: number
    logoUrl?: string

    platformId?: number
    platformUrl?: string
}

export enum E_IsSoonExpire {
    /**
     * 即将过期提醒
     */
    Alert = 1,

    /**
     * 不提醒
     */
    None = 0,
}

export interface ICurrentUserInfo {
    isSoonExpire: E_IsSoonExpire
    expireTime: string
    /**
     * 权限列表
     */
    permissions: string[]
    /**
     * 角色列表
     */
    roles: string[]
    user: ICurrentUser
}

export interface IUserMenu {
    children: IUserMenu[]
    component: string
    icon: string
    id: number
    isCache: number
    isFrame: number
    menuName: string
    menuType: E_MenuType
    orderNum: number
    parentId: number
    path: string
    perms: string
    query: string
    status: E_MenuStatus
    visible: number
}

export type IUserCurrentSysMenusData = IUserMenu[]

export interface IUserAddParams {
    adminFlag: number
    areaId: number
}

export interface IUserSwitchOpenStatusParams {
    status: E_StatusString
    userId: number
}

export interface IUserResetPasswordParams {
    adminPassword: string
    newPassword: string
    userId: number
}

export interface IUserRolesFindListParams {
    roleKey?: string
    roleName?: string
    status?: number
    userId?: number
    startTime?: string
    endTime?: string
}

export interface IUserRoleItem {
    id: number
    roleName: string
    createTime: string
    roleKey: string
    checkStatus: number
}

export type IUserRolesFindListData = IUserRoleItem[]

export interface IUserUpdateRolesParams {
    roleIds: number[]
    userId: number
}

export interface IUserOpenAppMessageStatusParams {
    status: E_PushAppMessage
    userId: number
}

//#region 角色

export interface IRoleAddParams {
    roleName: string
    roleSort: number
    status: E_Status
    roleKey: string
    menuIds: number[]
    remark: string
}

export interface IRoleEditParams extends IRoleAddParams {
    id: number
}

export type IRoleListParams = IPageParams & Partial<{
    roleKey: string
    roleName: string
    status: E_Status
    startTime: string
    endTime: string
    userId: number
}>

export interface IRoleListItem {
    companyId: number
    dataScope: E_DataScope
    deptCheckStrictly: boolean
    id: number
    menuCheckStrictly: boolean
    menuIds: null
    permissions: null
    remark: string
    roleKey: string
    roleName: string
    roleSort: number
    status: E_Status
}

export type IRoleListData = IPageData<IRoleListItem>

//#endregion

/**
 * $t('enum.E_MenuStatus.Normal')
 * $t('enum.E_MenuStatus.Blocked')
 */
export enum E_MenuStatus {
    Normal = 0,
    Blocked = 1,
}

/**
 * 菜单类型
 * $t('enum.E_MenuType.Content')
 * $t('enum.E_MenuType.Menu')
 * $t('enum.E_MenuType.Button')
 */
export enum E_MenuType {
    /**
     * 目录
     */
    Content = 'M',
    /**
     * 菜单
     */
    Menu = 'C',
    /**
     * 按钮
     */
    Button = 'F'
}

export const E_MenuType__Invert = _.invert(E_MenuType)

//#region menu
export interface IMenuListItem {
    id: number
    /**
     * 子菜单
     */
    children: IMenuListItem[];
    /**
     * 组件路径
     */
    component: string;
    /**
     * 菜单图标
     */
    icon: string;
    /**
     * 是否缓存（0缓存 1不缓存）
     */
    isCache: E_YesNoInverse;
    /**
     * 是否为外链（0是 1否）
     */
    isFrame: E_YesNoInverse
    /**
     * 菜单名称
     */
    menuName: string;
    /**
     * 类型（M目录 C菜单 F按钮）
     */
    menuType: E_MenuType;
    /**
     * 显示顺序
     */
    orderNum: number;
    /**
     * 父菜单ID
     */
    parentId: number;
    /**
     * 路由地址
     */
    path: string;
    /**
     * 权限字符串
     */
    perms: string;
    /**
     * 路由参数
     */
    query: string;
    /**
     * 菜单状态（0正常 1停用）
     */
    status: E_MenuStatus;
    /**
     * 显示状态（0显示 1隐藏）
     */
    visible: E_YesNoInverse;
}

export type IMenuListData = IMenuListItem[]

export interface IMenuCreateSaveParams {
    id?: number
    parentId: number
    menuType: E_MenuType
    menuName: string
    orderNum: number
    path: string
    status: E_MenuStatus
    perms?: string
}

//#endregion