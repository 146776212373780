/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import {
    E_Axis,
    E_AxisType,
    E_CollectMode,
    E_CollectWork,
    E_InstallMethod,
    E_MHFFlag,
    E_SensorDirection,
    E_SensorKlass
} from "@/api/moudules/sensor.types";
import { E_TimeUnit, toSeconds } from "@/utils/time";
import { E_AlarmType, HZList } from '@/api/enums';
import { toOptionPair } from './util';
import { EVT40_img, VB11_img, VB12_img, VB21_img, VB22H_img, VB31_img, VB32_img, VB40_img, VB41P_img, VB42P_img, VB50_img, VB60_img, VBE50_img, VBL21_img, VBS40_img, vbl11_img, vbs50h_img, RVT50_img, PT100_img, GTS40A_img, VBL12_img } from '@/assets/sensor';
import { IOptionStringItem } from '@/api/types';
import Vue from 'vue';
import { E_Eigenvalue } from '@/types';
import { isXYGT, XYGT_SensorKlassTable } from '@/custom/sensor';
import { fixRSSI } from '@/sensor/VBE50';


/**
 * 工作方式选项
 */
export const CollectWorkOptions = Object.freeze([
    E_CollectWork.Normal,
])

/**
 * 只有LoRa网关才有`时间管理模式`
 */
export const CollectWorkAllOptions = Object.freeze([
    ...CollectWorkOptions,
    E_CollectWork.Timebase,
])

/**
 * 采集模式选项
 */
export const CollectModeOptions = Object.freeze([
    E_CollectMode.Disable,
    E_CollectMode.EveryTime,
    E_CollectMode.Interval,
    E_CollectMode.Daily,
])

/**
 * 时间模式下的采集模式选择
 */
export const CollectModeTimebaseOptions = Object.freeze([
    E_CollectMode.Every3Hour,
    E_CollectMode.Every6Hour,
    E_CollectMode.Every12Hour,
    E_CollectMode.Every24Hour,
])


export const ZSsOptions = _.map(_.range(0, 11), x => {
    return {
        label: x,
        value: x,
    }
})

/**
 * 间隔采集选项
 */
export const CollectSpaceOptions = (vm: Vue) => Object.freeze(
    _.map(_.range(1, 20), i => {
        return {
            label: `${i}${vm.$t('unit.number')}`,
            value: i,
        }
    })
)

export const CollectTimeOptions = (vm: Vue) => Object.freeze(_.map(_.range(0, 23), i => {
    return {
        label: `${i}${vm.$t('unit.point')}`,
        value: i,
    }
}))

/**
 * 侦听时长 
 */
export const ListeningOptions = (vm: Vue) => [
    { label: '0', value: 0 },
    { label: `5${vm.$t('unit.seconds')}`, value: 5 },
    { label: `30${vm.$t('unit.seconds')}`, value: 30 },
    { label: `1${vm.$t('unit.minutes')}`, value: toSeconds(1, E_TimeUnit.Minute) },
    { label: `5${vm.$t('unit.minutes')}`, value: toSeconds(5, E_TimeUnit.Minute) },
]

const SleepTOptionsPart0 = (vm: Vue) => [
    { label: vm.$t('sensor.notSleep'), value: 0 },
    { label: `30${vm.$t('unit.seconds')}`, value: toSeconds(30, E_TimeUnit.Second) },
]

const SleepTOptionsPart1 = (vm: Vue) => [
    { label: `1${vm.$t('unit.minutes')}`, value: toSeconds(1, E_TimeUnit.Minute) },
    { label: `5${vm.$t('unit.minutes')}`, value: toSeconds(5, E_TimeUnit.Minute) },
    { label: `10${vm.$t('unit.minutes')}`, value: toSeconds(10, E_TimeUnit.Minute) },
    { label: `15${vm.$t('unit.minutes')}`, value: toSeconds(15, E_TimeUnit.Minute) },
    { label: `30${vm.$t('unit.minutes')}`, value: toSeconds(30, E_TimeUnit.Minute) },
]
const SleepTOptionsPart2 = (vm: Vue) => _.map(_.range(1, 24 + 1), hour => {
    return {
        label: `${hour}${vm.$t('unit.hours')}`,
        value: toSeconds(hour, E_TimeUnit.Hour)
    }
})

const SleepTOptions1Min = (vm: Vue) => [
    { label: `1${vm.$t('unit.minutes')}`, value: toSeconds(1, E_TimeUnit.Minute) },
]

const SleepTOptionsVB43 = (vm: Vue) => [
    { label: vm.$t('sensor.notSleep'), value: 0 },
    { label: `5${vm.$t('unit.minutes')}`, value: toSeconds(5, E_TimeUnit.Minute) },
    { label: `10${vm.$t('unit.minutes')}`, value: toSeconds(10, E_TimeUnit.Minute) },
    { label: `15${vm.$t('unit.minutes')}`, value: toSeconds(15, E_TimeUnit.Minute) },
]

/**
 * 休眠时长选项
 */
export function SleepTOptions(vm: Vue, klass: E_SensorKlass) {
    if (isXYGT(klass)) {
        return SleepTOptions1Min(vm)
    }
    return Object.freeze([
        ...(klass === E_SensorKlass.VB24H ? [] : SleepTOptionsPart0(vm)),
        ...SleepTOptionsPart1(vm),
        ...SleepTOptionsPart2(vm),
    ])
}


const DataUploadOptionsPart0 = (vm: Vue) => [
    { label: vm.$t('sensor.notDataUploadDealy'), value: 0 },
]

const DataUploadOptionsPart1 = (vm: Vue) => [
    { label: `1${vm.$t('unit.minutes')}`, value: toSeconds(1, E_TimeUnit.Minute) },
    { label: `5${vm.$t('unit.minutes')}`, value: toSeconds(5, E_TimeUnit.Minute) },
    { label: `10${vm.$t('unit.minutes')}`, value: toSeconds(10, E_TimeUnit.Minute) },
    { label: `15${vm.$t('unit.minutes')}`, value: toSeconds(15, E_TimeUnit.Minute) },
    { label: `30${vm.$t('unit.minutes')}`, value: toSeconds(30, E_TimeUnit.Minute) },
]
const DataUploadOptionsPart2 = (vm: Vue) => _.map(_.range(1, 24 + 1), hour => {
    return {
        label: `${hour}${vm.$t('unit.hours')}`,
        value: toSeconds(hour, E_TimeUnit.Hour)
    }
})

const DataUploadOptionsVB43 = (vm: Vue) => [
    { label: vm.$t('sensor.notDataUploadDealy'), value: 0 },
    { label: `5${vm.$t('unit.minutes')}`, value: toSeconds(5, E_TimeUnit.Minute) },
    { label: `10${vm.$t('unit.minutes')}`, value: toSeconds(10, E_TimeUnit.Minute) },
    { label: `15${vm.$t('unit.minutes')}`, value: toSeconds(15, E_TimeUnit.Minute) },
]


/**
 * 上传周期选项
 */
export function DataUploadOptions(vm: Vue, klass: E_SensorKlass) {
    if (klass === E_SensorKlass.VB43) {
        return DataUploadOptionsVB43(vm)
    }
    return Object.freeze([
        ...(klass === E_SensorKlass.VB24H ? [] : DataUploadOptionsPart0(vm)),
        { label: `30${vm.$t('unit.seconds')}`, value: toSeconds(30, E_TimeUnit.Second) },
        ...DataUploadOptionsPart1(vm),
        ...DataUploadOptionsPart2(vm),
    ])
}

const DataSamplingOptionsPart0 = (vm: Vue) => [
    { label: vm.$t('sensor.notDataSamplingDealy'), value: 0 },
    { label: `30${vm.$t('unit.seconds')}`, value: toSeconds(30, E_TimeUnit.Second) },
]

const DataSamplingOptionsPart1 = (vm: Vue) => [
    { label: `1${vm.$t('unit.minutes')}`, value: toSeconds(1, E_TimeUnit.Minute) },
    { label: `5${vm.$t('unit.minutes')}`, value: toSeconds(5, E_TimeUnit.Minute) },
    { label: `10${vm.$t('unit.minutes')}`, value: toSeconds(10, E_TimeUnit.Minute) },
    { label: `15${vm.$t('unit.minutes')}`, value: toSeconds(15, E_TimeUnit.Minute) },
    { label: `30${vm.$t('unit.minutes')}`, value: toSeconds(30, E_TimeUnit.Minute) },
]
const DataSamplingOptionsPart2 = (vm: Vue) => _.map(_.range(1, 24 + 1), hour => {
    return {
        label: `${hour}${vm.$t('unit.hours')}`,
        value: toSeconds(hour, E_TimeUnit.Hour)
    }
})

const DataSamplingOptionsVB43 = (vm: Vue) => [
    { label: vm.$t('sensor.notDataSamplingDealy'), value: 0 },
    { label: `5${vm.$t('unit.minutes')}`, value: toSeconds(5, E_TimeUnit.Minute) },
    { label: `10${vm.$t('unit.minutes')}`, value: toSeconds(10, E_TimeUnit.Minute) },
    { label: `15${vm.$t('unit.minutes')}`, value: toSeconds(15, E_TimeUnit.Minute) },
]


/**
 * 采样周期选项
 */
export function DataSamplingOptions(vm: Vue, klass: E_SensorKlass) {
    if (klass === E_SensorKlass.VB43) {
        return DataSamplingOptionsVB43(vm)
    }
    return Object.freeze([
        ...(klass === E_SensorKlass.VB24H ? [] : DataSamplingOptionsPart0(vm)),
        ...DataSamplingOptionsPart1(vm),
        ...DataSamplingOptionsPart2(vm),
    ])
}

/**
 * 轴向选项
 */
export const SensorDirectionOptions = Object.freeze([
    E_SensorDirection.Horizontal,
    E_SensorDirection.Axial,
    E_SensorDirection.Vertical,
])

/**
 * 频率选项
 */
export const FreqOptions = Object.freeze([
    E_MHFFlag.Middle,
    E_MHFFlag.High,
])

/**
 * 轴向选项
 */
export const AxisSingleOptions = Object.freeze([
    { label: 'Z', value: E_Axis.Z },
])

export const AxisThreeOptions = Object.freeze([
    { label: 'XYZ', value: E_Axis.XYZ },
])

export const AxisFullOptions = Object.freeze([
    { label: 'X', value: E_Axis.X },
    { label: 'Y', value: E_Axis.Y },
    { label: 'Z', value: E_Axis.Z },
    { label: 'XYZ', value: E_Axis.XYZ },
])

export const AxisXYZOptions = Object.freeze([
    { label: 'X', value: E_Axis.X },
    { label: 'Y', value: E_Axis.Y },
    { label: 'Z', value: E_Axis.Z },
])

/**
 * 点数选项
 */
export const PointOptions = Object.freeze([
    { label: '1024', value: 1024 },
    { label: '2048', value: 2048 },
    { label: '4096', value: 4096 },
    { label: '8192', value: 8192 },
])

/**
 * 报警选择
 */
export const AlarmTypeOptions = Object.freeze([
    E_AlarmType.Vibration,
    E_AlarmType.Steepness,
    E_AlarmType.Crest,
    E_AlarmType.Temperature,
    E_AlarmType.Voltage,
    E_AlarmType.Offline,
    // 目前只有GCS40才有电流传感器选项
    // E_AlarmType.Electricity,
])

/**
 * 安装方式
 */
export const InstallMethodOptions = Object.freeze([
    E_InstallMethod.MAGNETISM,
    E_InstallMethod.BOLET,
])

/**
 * 无线频段
 */
export const WirelessFrequencyOptions = Object.freeze([{
    label: '2450MHz', value: '2450'
}])
/**
 * 无线通道 
 */
export const WirelessChannelOptions = (vm: Vue) => _.map(_.range(0, 8), value => {
    return {
        label: `${vm.$t('unit.channel')} ${value}`,
        value,
    }
})

export const SamplingIntervalOptionsDebugMode0 = (vm: Vue) => [
    { label: vm.$t('common.secondsTemplate', { value: 30 }), value: 30 },
    ..._.map([1, 3], x => ({ label: vm.$t('common.minutesTemplate', { value: x }), value: toSeconds(x, E_TimeUnit.Minute) }))
]
export const SamplingIntervalOptionsDebugModeFull = (vm: Vue) => [
    ...SamplingIntervalOptionsDebugMode0(vm),
    { label: vm.$t('common.minutesTemplate', { value: 5 }), value: toSeconds(5, E_TimeUnit.Minute) }
]

/**
 * 中频采样间隔 
 */
export const SamplingIntervalOptionsMiddle = (vm: Vue, debugMode: boolean) => [
    ...debugMode ? SamplingIntervalOptionsDebugMode0(vm) : [],
    ..._.map([5, 10, 15, 30], x => ({ label: vm.$t('common.minutesTemplate', { value: x }), value: toSeconds(x, E_TimeUnit.Minute) })),
    ..._.map(_.range(1, 25), x => ({ label: vm.$t('common.hourTemplate', { value: x }), value: toSeconds(x, E_TimeUnit.Hour) })),
]

/**
 *  中频采样间隔 
 */
export const SamplingIntervalOptionsHigh = (vm: Vue, debugMode: boolean) => [
    ...debugMode ? SamplingIntervalOptionsDebugModeFull(vm) : [],
    ..._.map(_.range(1, 25), x => ({ label: vm.$t('common.hourTemplate', { value: x }), value: toSeconds(x, E_TimeUnit.Hour) })),
]

/**
 *  中频采样间隔 
 */
export const SamplingIntervalOptionsLongWave = (vm: Vue, debugMode: boolean) => [
    ...debugMode ? SamplingIntervalOptionsDebugModeFull(vm) : [],
    ..._.map(_.range(1, 8), x => ({ label: vm.$t('common.dayTemplate', { value: x }), value: toSeconds(x, E_TimeUnit.Day) }))
]

/**
 * 采样倍数
 */
export const SamplingMultipleOptions = _.range(1, 11)

export interface ISensorKlassTableItem {
    klass: E_SensorKlass
    points?: number[]
    // 有的有限制需要这么处理
    pointsFn?: (kind: E_MHFFlag, alex: E_Axis, mhfHz: string) => number[]
    useInstallMethod?: boolean
    hasSleepT?: boolean
    /**
     * 传感器灵敏度和偏置电压
     */
    useSensorSensitivityAndVoltage?: boolean
    modelImg: string
    hasDebugMode?: boolean
    hasSendWaveStatus?: boolean
    mhfHzs?: {
        kind: E_MHFFlag
        options?: IOptionStringItem[]
        optionsLabelKey?: string
        axles?: E_AxisType
        /**
         * 采样间隔
         */
        samplingIntervalOptions?: (vm: Vue, debugMode?: boolean) => any[]
    }[]
    /**
     * 是否支持轴位移
     */
    hasAxialDisplacement?: boolean
    /**
     * 是否有轴
     */
    hasAxis: boolean
    /**
     * 是否有采集方式参数
     */
    hasCollectMode?: boolean
    /**
     * 是否有采集方式参数(在参数配置中)
     */
    hasCollectModeInConfig?: boolean
    /**
     * 是否有自学习功能
     */
    hasOpenLearn: boolean
    /**
     * 是否有故障自诊断功能
     */
    hasFaultDiagnosis: boolean
    /**
     * 是否有特征值上传
     */
    hasDataUpload?: boolean
    /**
     * 是否有特征值采集
     */
    hasDataSampling?: boolean
    /**
     * 是否有侦听时长
     */
    hasListening?: boolean
    /**
     * 是否有启用波形采集
     */
    hasOpenWave?: boolean
    /**
     * 是否有高频Z轴启停
     */
    hasHighZSs?: boolean
    /**
     * 是否有中频Z轴启停
     */
    hasLowZSs?: boolean
    /**
     * 是否有无线通道
     */
    hasWirelessChannel?: boolean
    /**
     * 是否有无线频段   
     */
    hasWirelessFrequency?: boolean
    /**
     * 使用波形采集方式
     */
    useWaveCollect?: boolean
    /**
     * 休眠时长
     */
    disableSleepT?: boolean

    /**
     * 是否采用采样倍数
     */
    hasSamplingMultiple?: boolean

    /**
     * 是否有MQTT配置参数
     */
    hasMqtt?: boolean

    /**
     * 是否有读取MQTT/Sim配置参数
     */
    hasReadSimMqttInfo?: boolean

    /**
     * 是否有读取软件版本号，硬件版本号功能
     */
    hasReadSoftwareHardwareVersion?: boolean

    /**
     * 是否有软件版本号，硬件版本号功能(在特征值中)
     */
    hasSoftwareHardwareVersion?: boolean

    /**
     * 是否有读取信号强度功能
     */
    hasReadRSSI?: boolean

    /**
     * 是否有信号强度功能(在特征值中)
     */
    hasRSSI?: boolean

    fixRSSI?: (value: number) => number

    // /*
    //  * 读取参数
    //  */
    // hasReadConfig?: boolean

    /**
     * 设置LoRa参数
     */
    hasSetLoRaConfig?: boolean

    /**
     * 验证上传时间大于采样时间
     */
    validateSamplingSleepT?: boolean

    /**
     * 是否有诊断配置功能
     */
    hasDiagnose?: boolean

    /**
     * 是否有设置传感器时间功能
     */
    hasSettingDateTime?: boolean

    eigenvalueFeatures?: E_Eigenvalue[]

    hasTagName?: boolean
}

export function hasReadConfig(t: ISensorKlassTableItem) {
    if (!t) {
        return false
    }
    const {
        hasReadRSSI,
        hasReadSoftwareHardwareVersion,
    } = t
    return hasReadRSSI || hasReadSoftwareHardwareVersion
}

export const SensorOnlyKlassTable: readonly ISensorKlassTableItem[] = Object.freeze([
    {
        klass: E_SensorKlass.VB11,
        modelImg: VB11_img,
        useInstallMethod: true,
        mhfHzs: [
            { kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [HZList.HZ_6660] }
        ],
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasSleepT: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            E_Eigenvalue.Angle,
            E_Eigenvalue.Battery,
            // E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
            // E_Eigenvalue.Ambienttem,
        ]
    },
    {
        klass: E_SensorKlass.VB12,
        modelImg: VB12_img,
        useInstallMethod: true,
        mhfHzs: [
            { kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [HZList.HZ_6660] }
        ],
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasSleepT: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            E_Eigenvalue.Angle,
            E_Eigenvalue.Battery,
            // E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
            // E_Eigenvalue.Ambienttem,
        ]
    },
    {
        klass: E_SensorKlass.VB21,
        modelImg: VB21_img,
        useInstallMethod: true,
        points: [
            1024,
            2048,
        ],
        mhfHzs: [
            {
                kind: E_MHFFlag.None, axles: E_AxisType.Full, options: [
                    HZList.HZ_6660,
                    HZList.HZ_3330,
                    HZList.HZ_1660,
                    HZList.HZ_833,
                    HZList.HZ_44440]
            }
        ],
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasSleepT: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            E_Eigenvalue.Angle,
            E_Eigenvalue.Battery,
            // E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
            // E_Eigenvalue.Ambienttem,
        ]
    },
    {
        klass: E_SensorKlass.VB22H,
        modelImg: VB22H_img,
        useInstallMethod: true,
        pointsFn: (kind: E_MHFFlag, alex: E_Axis, mhfHz: string) => {
            if (kind !== E_MHFFlag.Middle) {
                return [1024, 2048, 4096, 8192]
            }
            if (alex === E_Axis.XYZ) {
                return [1024, 2048]
            }
            if (mhfHz === '833Hz') {
                return [1024, 2048, 4096]
            }
            return [1024, 2048, 4096, 8192]
        },
        mhfHzs: [
            {
                kind: E_MHFFlag.High, axles: E_AxisType.Single, options: [
                    HZList.HZ_25600, HZList.HZ_12800, HZList.HZ_2560,]
            },
            {
                kind: E_MHFFlag.Middle, axles: E_AxisType.Full, options: [
                    HZList.HZ_6660, HZList.HZ_3330, HZList.HZ_1660, HZList.HZ_833,
                ]
            },
        ],
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasSleepT: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            E_Eigenvalue.Angle,
            E_Eigenvalue.Battery,
            // E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
            // E_Eigenvalue.Ambienttem,
        ]
    },
    {
        klass: E_SensorKlass.VB24H,
        modelImg: VB12_img,
        useInstallMethod: true,
        pointsFn: (kind: E_MHFFlag, alex: E_Axis, mhfHz: string) => {
            return {
                [E_MHFFlag.Middle]: [1024, 2048, 4096,],
                [E_MHFFlag.High]: [8192, 16384, 32768, 65536],
                [E_MHFFlag.LongWave]: [131072, 262144, 524288, 1048576],
            }[kind]
        },
        hasSendWaveStatus: true,
        hasDebugMode: true,
        mhfHzs: [
            {
                kind: E_MHFFlag.Middle, axles: E_AxisType.Three, options: [
                    HZList.HZ_833,
                    HZList.HZ_1666,
                    HZList.HZ_3332,
                    HZList.HZ_6664,],
                // $t('common.sampleFrequencyMiddle')
                optionsLabelKey: 'common.sampleFrequencyMiddle',
                samplingIntervalOptions: SamplingIntervalOptionsMiddle,
            },
            {
                kind: E_MHFFlag.High, axles: E_AxisType.Single, options: [
                    HZList.HZ_2560,
                    HZList.HZ_5120,
                    HZList.HZ_12800,
                    HZList.HZ_25600,
                    HZList.HZ_51200,],
                // $t('common.sampleFrequencyHigh')
                optionsLabelKey: 'common.sampleFrequencyHigh',
                samplingIntervalOptions: SamplingIntervalOptionsHigh,
            },
            {
                kind: E_MHFFlag.LongWave,
                samplingIntervalOptions: SamplingIntervalOptionsLongWave,
            },
        ],
        useWaveCollect: true,
        hasSleepT: false,
        hasAxis: true,
        hasCollectModeInConfig: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasDataUpload: true,
        hasDataSampling: false,
        hasListening: false,
        hasOpenWave: true,
        hasHighZSs: true,
        hasLowZSs: true,
        hasWirelessChannel: true,
        hasWirelessFrequency: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            E_Eigenvalue.Angle,
            E_Eigenvalue.Battery,
            E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
            // E_Eigenvalue.Ambienttem,
        ]
    },
    // {
    //     klass: E_SensorKlass.VB23P,
    //     points: [
    //         1024,
    //         2048,
    //     ],
    //     mhfHzs: [
    //         {
    //             kind: E_MHFFlag.None, axles: E_AxisType.Full, options: ['6660Hz',
    //                 '3330Hz',
    //                 '1660Hz',
    //                 '833Hz',
    //                 '44440Hz']
    //         }
    //     ]
    // },
    {
        klass: E_SensorKlass.VB31,
        modelImg: VB31_img,
        useInstallMethod: true,
        mhfHzs: [
            {
                kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [
                    HZList.HZ_3330, HZList.HZ_1660, HZList.HZ_833,]
            }
        ],
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasSleepT: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            E_Eigenvalue.Angle,
            E_Eigenvalue.Battery,
            // E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
            // E_Eigenvalue.Ambienttem,
        ]
    },
    // {
    //     klass: E_SensorKlass.VB32,
    //     modelImg: VB32_img,
    //     useInstallMethod: true,
    //     mhfHzs: [
    //         {
    //             kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [
    //                 HZList.HZ_3330, HZList.HZ_1660, HZList.HZ_833,]
    //         }
    //     ],
    //     hasAxis: true,
    //     hasCollectMode: true,
    //     hasOpenLearn: true,
    //     hasFaultDiagnosis: true,
    //     hasSleepT: true,
    //     hasDiagnose: true,
    //     eigenvalueFeatures: [

    //     ]
    // },
    {
        klass: E_SensorKlass.VB40,
        modelImg: VB40_img,
        useInstallMethod: true,
        points: [1600],
        mhfHzs: [
            { kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [HZList.HZ_6660] }
        ],
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasSleepT: true,
        hasDiagnose: true,
        hasReadRSSI: true,
        hasSetLoRaConfig: true,
        hasReadSoftwareHardwareVersion: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            E_Eigenvalue.Angle,
            E_Eigenvalue.Battery,
            // E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
        ]

    },
    // {
    //     klass: E_SensorKlass.VB41P,
    //     modelImg: VB41P_img,
    //     mhfHzs: [
    //         { kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [HZList.HZ_6660] }
    //     ],
    //     hasAxis: true,
    //     hasCollectMode: true,
    //     hasOpenLearn: true,
    //     hasFaultDiagnosis: true,
    //     hasSleepT: true,
    //     hasDiagnose: true,
    //     eigenvalueFeatures: [

    //     ]
    // },
    // {
    //     klass: E_SensorKlass.VB42P,
    //     modelImg: VB42P_img,
    //     mhfHzs: [
    //         { kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [HZList.HZ_6660] }
    //     ],
    //     hasAxis: true,
    //     hasCollectMode: true,
    //     hasOpenLearn: true,
    //     hasFaultDiagnosis: true,
    //     hasSleepT: true,
    //     hasDiagnose: true,
    //     eigenvalueFeatures: [

    //     ]
    // },
    {
        klass: E_SensorKlass.VB43,
        modelImg: VB40_img,
        useInstallMethod: true,
        pointsFn(kind: E_MHFFlag, alex: E_Axis, mhfHz: string) {
            if (alex === E_Axis.XYZ) {
                return [1600]
            } else {
                return [2048, 4096]
            }
        },
        mhfHzs: [
            { kind: E_MHFFlag.None, axles: E_AxisType.Full, options: [HZList.HZ_6660, HZList.HZ_3330] }
        ],
        validateSamplingSleepT: true,
        useWaveCollect: false,
        hasSleepT: true,
        hasDataUpload: false,
        hasDataSampling: true,
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasReadRSSI: true,
        hasReadSoftwareHardwareVersion: true,
        hasSetLoRaConfig: true,
        hasDiagnose: true,
        hasSettingDateTime: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            E_Eigenvalue.Angle,
            E_Eigenvalue.Battery,
            // E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
            // E_Eigenvalue.Ambienttem,
        ]
    },
    {
        klass: E_SensorKlass.VB50,
        useInstallMethod: true,
        modelImg: VB50_img,
        mhfHzs: [
            { kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [HZList.HZ_6660] }
        ],
        hasSleepT: true,
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasMqtt: true,
        hasReadSimMqttInfo: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            E_Eigenvalue.Angle,
            E_Eigenvalue.Battery,
            E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
        ]
    },
    {
        klass: E_SensorKlass.VBE50,
        useInstallMethod: true,
        modelImg: VB50_img,
        hasSleepT: true,
        hasAxis: true,
        hasCollectMode: false,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasMqtt: true,
        hasReadSimMqttInfo: true,
        hasDiagnose: true,
        hasSoftwareHardwareVersion: true,        
        hasRSSI: true,
        fixRSSI: fixRSSI,
        eigenvalueFeatures: [
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            // E_Eigenvalue.RotationalSpeed,            
            E_Eigenvalue.Battery,
            E_Eigenvalue.Rssi,
            E_Eigenvalue.Ambienttem,
        ]
    },
    // {
    //     klass: E_SensorKlass.VB60,
    //     modelImg: VB60_img,
    //     mhfHzs: [
    //         { kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [HZList.HZ_6660] }
    //     ],
    //     hasAxis: true,
    //     hasCollectMode: true,
    //     hasOpenLearn: true,
    //     hasFaultDiagnosis: true,
    //     hasSleepT: true,
    //     hasDiagnose: true,
    //     eigenvalueFeatures: [

    //     ]
    // },
    {
        klass: E_SensorKlass.VBL11,
        useInstallMethod: true,
        modelImg: vbl11_img,
        points: [1600],
        mhfHzs: [
            { kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [HZList.HZ_6660] }
        ],
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasSleepT: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            E_Eigenvalue.Angle,
            // E_Eigenvalue.Battery,
            // E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
            // E_Eigenvalue.Ambienttem,
        ]
    },
    {
        klass: E_SensorKlass.VBL12,
        useInstallMethod: true,
        modelImg: VBL12_img,
        points: [1600],
        mhfHzs: [
            { kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [HZList.HZ_6660] }
        ],
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasSleepT: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            E_Eigenvalue.Angle,
            // E_Eigenvalue.Battery,
            // E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
            // E_Eigenvalue.Ambienttem,
        ]
    },
    // {
    //     klass: E_SensorKlass.VBL21,
    //     modelImg: VBL21_img,
    //     mhfHzs: [
    //         { kind: E_MHFFlag.None, axles: E_AxisType.Single }

    //     ],
    //     hasAxis: true,
    //     hasCollectMode: true,
    //     hasOpenLearn: true,
    //     hasFaultDiagnosis: true,
    //     hasSleepT: true,
    //     hasDiagnose: true,
    //     eigenvalueFeatures: [

    //     ]
    // },
    // {
    //     klass: E_SensorKlass.VBS40,
    //     modelImg: VBS40_img,
    //     mhfHzs: [
    //         { kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [HZList.HZ_6660] }
    //     ],
    //     hasAxis: true,
    //     hasCollectMode: true,
    //     hasOpenLearn: true,
    //     hasFaultDiagnosis: true,
    //     hasSleepT: true,
    //     hasDiagnose: true,
    //     eigenvalueFeatures: [

    //     ]
    // },
    // {
    //     klass: E_SensorKlass.VBS50H,
    //     modelImg: vbs50h_img,
    //     points: [
    //         1024,
    //         2048,
    //         4096,
    //         8192
    //     ],
    //     mhfHzs: [
    //         { kind: E_MHFFlag.None, axles: E_AxisType.Single, options: [HZList.HZ_25600] }
    //     ],
    //     hasAxis: true,
    //     hasCollectMode: true,
    //     hasOpenLearn: true,
    //     hasFaultDiagnosis: true,
    //     hasSleepT: true,
    //     hasDiagnose: true,
    //     eigenvalueFeatures: [

    //     ]
    // },
    // {
    //     klass: E_SensorKlass.EVT40,
    //     modelImg: EVT40_img,
    //     mhfHzs: [
    //         { kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [HZList.HZ_6660] }
    //     ],
    //     hasAxis: true,
    //     hasCollectMode: true,
    //     hasOpenLearn: true,
    //     hasFaultDiagnosis: true,
    //     hasSleepT: true,
    //     hasDiagnose: true,
    //     eigenvalueFeatures: [

    //     ]
    // },
    {
        klass: E_SensorKlass.EVT41,
        modelImg: EVT40_img,
        points: [1600],
        mhfHzs: [
            { kind: E_MHFFlag.Middle, axles: E_AxisType.Three, options: [HZList.HZ_6660, HZList.HZ_3330, HZList.HZ_1660, HZList.HZ_833, HZList.HZ_416] }
        ],
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasSleepT: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            E_Eigenvalue.Angle,
            E_Eigenvalue.Battery,
            // E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
        ]
    },
    {
        klass: E_SensorKlass.RVT50,
        modelImg: RVT50_img,
        mhfHzs: [
            { kind: E_MHFFlag.None, axles: E_AxisType.Single, options: [HZList.HZ_2000] }
        ],
        useSensorSensitivityAndVoltage: true,
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            E_Eigenvalue.Acceleration,
            E_Eigenvalue.VibrationSeverity,
            E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            E_Eigenvalue.Kurtosis,
            E_Eigenvalue.CrestFactor,
            E_Eigenvalue.EnvelopeDemodulation,
            E_Eigenvalue.RotationalSpeed,
            // E_Eigenvalue.Angle,
            E_Eigenvalue.Battery,
            E_Eigenvalue.Rssi,
            E_Eigenvalue.VFreq,
            // E_Eigenvalue.Ambienttem,
        ]
    },
    // {
    //     klass: E_SensorKlass.GDS40,
    //     useInstallMethod: false,
    //     modelImg: vbl11_img,
    //     mhfHzs: [
    //         { kind: E_MHFFlag.None, axles: E_AxisType.Three, options: [HZList.HZ_6660] }
    //     ],
    //     hasAxialDisplacement: true,
    //     hasAxis: true,
    //     hasCollectMode: true,
    //     hasOpenLearn: true,
    //     hasFaultDiagnosis: true,
    //     hasSleepT: true,
    //     hasDiagnose: true,
    //     eigenvalueFeatures: [

    //     ]
    // },
    {
        klass: E_SensorKlass.PT100,
        useInstallMethod: false,
        modelImg: PT100_img,
        hasAxis: false,
        hasCollectMode: false,
        hasOpenLearn: false,
        hasFaultDiagnosis: false,
        hasSleepT: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            // E_Eigenvalue.Acceleration,
            // E_Eigenvalue.VibrationSeverity,
            // E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            // E_Eigenvalue.Kurtosis,
            // E_Eigenvalue.CrestFactor,
            // E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            // E_Eigenvalue.Angle,
            // E_Eigenvalue.Battery,
            // E_Eigenvalue.Rssi,
            // E_Eigenvalue.VFreq,
            // E_Eigenvalue.Ambienttem,
        ]
    },
    {
        klass: E_SensorKlass.GTS40A,
        useInstallMethod: false,
        modelImg: GTS40A_img,
        hasAxis: false,
        hasCollectMode: false,
        hasOpenLearn: false,
        hasFaultDiagnosis: false,
        hasSleepT: true,
        hasDiagnose: true,
        eigenvalueFeatures: [
            // E_Eigenvalue.Acceleration,
            // E_Eigenvalue.VibrationSeverity,
            // E_Eigenvalue.Offset,
            E_Eigenvalue.Temperature,
            E_Eigenvalue.TemperatureIncrease,
            // E_Eigenvalue.Kurtosis,
            // E_Eigenvalue.CrestFactor,
            // E_Eigenvalue.EnvelopeDemodulation,
            // E_Eigenvalue.RotationalSpeed,
            // E_Eigenvalue.Angle,
            E_Eigenvalue.Battery,
            // E_Eigenvalue.Rssi,
            // E_Eigenvalue.VFreq,
            // E_Eigenvalue.Ambienttem,
        ]
    },
    ...XYGT_SensorKlassTable,
])


export const CollectCardChannelKlassTable: readonly ISensorKlassTableItem[] = Object.freeze([
    {
        klass: E_SensorKlass.CollectCard_Temperature,
        modelImg: null,
        useInstallMethod: true,
        mhfHzs: [],
        hasAxis: true,
        hasCollectMode: false,
        hasOpenLearn: false,
        hasFaultDiagnosis: false,
        hasDiagnose: false,
    },
    {
        klass: E_SensorKlass.CollectCard_RotateSpeed,
        modelImg: null,
        useInstallMethod: true,
        mhfHzs: [],
        hasAxis: true,
        hasCollectMode: false,
        hasOpenLearn: false,
        hasFaultDiagnosis: true,
        hasDiagnose: true,
    },
    {
        klass: E_SensorKlass.CollectCard_Vibration,
        modelImg: null,
        useInstallMethod: true,
        mhfHzs: [],
        hasAxis: true,
        hasCollectMode: true,
        hasOpenLearn: true,
        hasFaultDiagnosis: true,
        hasSamplingMultiple: true,
        hasDiagnose: true,
    },
])

export const SensorKlassTable: readonly ISensorKlassTableItem[] = Object.freeze([
    ...SensorOnlyKlassTable,
    ...CollectCardChannelKlassTable,
])

/**
 * 传感器种类
 */
export const SensorKlassOptions = Object.freeze(_.map(SensorOnlyKlassTable, table =>
    toOptionPair(table.klass)))


export const SensorOnlyKlassTableMaping = _.fromPairs(_.map(SensorOnlyKlassTable, item => {
    return [item.klass, item]
}))
