import { IMappingData } from "@/api/moudules/onlineDetection.types";
import { E_SensorDirection } from "@/api/moudules/sensor.types";
import { ECharts } from "echarts";
import _ from "lodash";

export interface ISelectedAction {
    name: string
    selected: Record<string, boolean>
    type: 'legendselectchanged'
}

type OnLegendSelectedFn = (x: string) => void

export function connectChartsLegend(charts: ECharts[], onLegendSelected?: OnLegendSelectedFn) {
    _.each(charts, chart => {
        let others = _.filter(charts, c => c !== chart)
        chart.on('legendselectchanged', (ev: ISelectedAction) => {
            const selectedPairs = _.toPairs(ev.selected)
            let selectLegend = _.find(selectedPairs, x => x[1])
            if (selectLegend) {
                let type = 'legendSelect'
                let legend = selectLegend[0]
                _.each(others, other => {
                    other.dispatchAction({
                        type,
                        name: legend,
                    })
                })
                if (onLegendSelected) {
                    onLegendSelected(selectLegend[0])
                }
            }
        })
    })
}

export interface ISensorDirectionConfig {
    x1: E_SensorDirection
    y1: E_SensorDirection
    z1: E_SensorDirection
}

const DirectionTable = {
    [E_SensorDirection.Horizontal]: 'H',
    [E_SensorDirection.Axial]: 'A',
    [E_SensorDirection.Vertical]: 'V',
}

export interface IXYZ {
    x: string
    y: string
    z: string
}

export function toSensorDirectionDisplay(config?: ISensorDirectionConfig): IXYZ {
    if (!config) {
        return {
            x: 'X',
            y: 'Y',
            z: 'Z',
        }
    }
    return {
        x: DirectionTable[config.x1] || 'X',
        y: DirectionTable[config.y1] || 'Y',
        z: DirectionTable[config.z1] || 'Z',
    }
}

export function toSensorDirectionDisplayFull(config?: ISensorDirectionConfig): IXYZ {
    if (!config) {
        return {
            x: 'X',
            y: 'Y',
            z: 'Z',
        }
    }
    let x1 = DirectionTable[config.x1]
    let y1 = DirectionTable[config.y1]
    let z1 = DirectionTable[config.z1]
    return {
        x: x1 ? `X(${x1})` : 'X',
        y: y1 ? `Y(${y1})` : 'Y',
        z: z1 ? `Z(${z1})` : 'Z',
    }
}

export function legendFormater(name: string, xyz?: IXYZ) {
    if (!xyz) {
        return name
    }
    const {
        x, y, z
    } = xyz
    switch (name) {
        case 'X':
            if (x !== 'X') {
                return `X(${x})`
            }
            break
        case 'Y':
            if (y !== 'Y') {
                return `Y(${y})`
            }
            break
        case 'Z':
            if (z !== 'Z') {
                return `Z(${z})`
            }
            break
    }
    return name
}
