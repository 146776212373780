// 倒谱图
import Vue from "vue";
import _ from 'lodash'
import { IXYZ, legendFormater } from "./utils";
import { getAxialColor, getPrimaryColor } from "@/utils/color";
import { Toolbox } from "@/utils/echarts";
import { AxisSplitLine, LineConfig } from "./consts";
import { toFixed } from "@/utils/math";

export interface ICepstrumConfig {
    vm: Vue
    x1: number[]
    y1: number[]
    y2: number[]
    y3: number[]
    xyz?: IXYZ
}

export function cepstrum({
    vm,
    x1,
    y1,
    y2,
    y3,
    xyz
}: ICepstrumConfig) {
    let unitX = 'ms'
    let unitY = "(m/s²)"
    let option: echarts.EChartsOption = {
        tooltip: {
            trigger: 'axis',
            formatter(params) {
                const {
                    encode,
                    data,
                } = params[0]
                const title = toFixed(data[encode.x[0]], 3) + 'ms'
                const items = _.map(params, param => {
                    const {
                        encode,
                        data,
                        seriesName,
                    } = param
                    let valueDisplay = toFixed(data[encode.y[0]], 3)
                    return seriesName + " : " + valueDisplay
                })
                let displayData = [title, ...items]
                return _.join(displayData, '<br/>')
            }
        },
        color: [getAxialColor('x'), getAxialColor('y'), getAxialColor('z')],
        legend: {
            left: 'center',
            top: 8,
            formatter(param) {
                return legendFormater(param, xyz)
            },
            textStyle: {
                color: getPrimaryColor(),
            },
            data: ['X', 'Y', 'Z'],
            selectedMode: 'single',
            selected: {
                X: false,
                Y: false,
                Z: true,
            }
        },
        grid: {
            left: 50,
            right: 50,
        },
        toolbox: Toolbox as any,
        dataset: {
            source: [
                x1,
                y1,
                y2,
                y3,
            ],
            dimensions: ['x', 'X', 'Y', 'Z'],
            seriesLayoutBy: 'row',
        },
        xAxis: {
            ...AxisSplitLine as any,
            type: 'value',
            axisLabel: {
                formatter: '{value} ' + unitX,
            },
            axisLine: {
                lineStyle: {
                    color: getPrimaryColor(),
                },
            },
        },
        yAxis: {
            ...AxisSplitLine as any,
            type: "value",
            name: vm.$t('management.amplitude') + unitY,
            nameTextStyle: {
                align: 'left',
            },
            axisLine: {
                lineStyle: {
                    color: getPrimaryColor(),
                },
            },
        },
        dataZoom: [
            {
                type: "inside",
            },
            {
                type: "slider",
            },
        ],
        series: [
            {
                name: 'X',
                type: 'line',
                encode: {
                    x: 'x',
                    y: 'X',
                },
                ...LineConfig,
            },
            {
                name: 'Y',
                type: 'line',
                encode: {
                    x: 'x',
                    y: 'Y'
                },
                ...LineConfig,
            },
            {
                name: 'Z',
                type: 'line',
                encode: {
                    x: 'x',
                    y: 'Z'
                },
                ...LineConfig,
            },
        ]
    }
    return option
}
