/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { baseUrl } from '@/utils/global'
import axios from '../axios'
import { IFileUploadData } from './file.types'
/**
 * 文件上传 
 */
export function fileUpload(file: File) {
    const f = new FormData()
    f.append('file', file)
    return axios<IFileUploadData>({
        method: 'post',
        url: '/file/upload',
        data: f,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

/**
 * 原始文件上传
 */
export function fileUploadRaw(file: File) {
    const f = new FormData()
    f.append('file', file)
    return axios<IFileUploadData>({
        method: 'post',
        url: '/file/fullUpload',
        data: f,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

/**
 * 文件全路径
 */
export function buildFullFileUrl(path: string) {
    if (!path) {
        return path
    }
    if (path.startsWith('http://') || path.startsWith('https://')) {
        return path
    }
    return baseUrl + '/image' + path
}
