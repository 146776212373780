/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { ICoordinate } from "../types"
import { ICommonStatusData } from "./screen.types"

/**
 * 报警等级
 * $t('enum.E_AlarmLevelString.A')
 * $t('enum.E_AlarmLevelString.B')
 * $t('enum.E_AlarmLevelString.C')
 * $t('enum.E_AlarmLevelString.D')
 */
export enum E_AlarmLevelString {
    A = '0',
    B = '1',
    C = '2',
    D = '3',
}

export const E_AlarmLevelString__Inverse = _.invert(E_AlarmLevelString)


export interface ILargeScreenAddAreaFileParams {
    fileId: number
    id: number
}

export interface ILargeScreenAddCompanyFileParams {
    fileId: number
    id: number
}

export interface ILargeScreenGetAlarmListParams {
    areaId: number
    companyId: number
}

export interface ILargeScreenGetAlarmListItem {
    alarmLevel: E_AlarmLevelString
    createTime: string
    equipmentName: string
}

export type ILargeScreenGetAlarmListData = ILargeScreenGetAlarmListItem[]

export interface ILargeScreenGetAreaFileParams {
    /**
     * 区域id
     */
    areaId: number
}

export type ILargeScreenGetAreaFileData = string


export interface ILargeScreenGetCompanyCoordinatesParams {
    companyId: number
}

/**
 * 公司区域坐标详情
 */
export interface IAreaCoordinatesListItem extends ICoordinate {
    /**
     * 区域id
     */
    areaId: number
}

export interface ILargeScreenGetCompanyCoordinatesData {
    /**
     * 区域坐标
     */
    areaCoordinatesList: IAreaCoordinatesListItem[]
    fileUrl: string
    id: number
}

export interface ILargeScreenGetCompanyScreenParams {
    companyId: number
}

/**
 * 区域大屏返回
 */
export interface IAreaScreenListItem extends ICoordinate {
    /**
     * 区域id
     */
    areaId: number
    /**
     * 区域名称
     */
    areaName: string
    /**
     * 设备数量
     */
    equipmentNum: number
    /**
     * 传感器数量
     */
    sensorNum: number
}

export interface ILargeScreenGetCompanyScreenData {
    /**
     * 区域信息
     */
    areaScreenList: IAreaScreenListItem[]
    fileUrl: string
    id: number
}

export interface ILargeScreenGetCompanyStatisticsParams {
    areaId: number
    companyId: number
}


export interface ILargeScreenGetCompanyStatisticsData {
    areaId: number
    companyId: number
    /**
     * 故障诊断数量
     */
    diagnosisNum: number
    /**
     * 设备数量
     */
    equipmentNum: number
    /**
     * 传感器数量
     */
    sensorNum: number
}

export interface ILargeScreenGetSensorStatisticsParams {
    areaId: number
    companyId: number
}

export interface ILargeScreenGetSensorStatisticsData {
    /**
     * 
     */
    calarmNum: number
    /**
     * 
     */
    dalarmNum: number
    /**
     * 
     */
    normalNum: number
    /**
     * 
     */
    offlineNum: number
}

export interface ILargeScreenSetCoordinatesParams extends ICoordinate {
    /**
     * 
     */
    areaId: number
}


export interface ILargeScreenGetOrdinaryCompanyStatisticsParams {
    companyId: number
    areaId: number
}

export interface ILargeScreenGetOrdinaryCompanyStatisticsData {
    /**
     * 区域数量
     */
    areaNum: number
    /**
     * 故障诊断数量
     */
    diagnosisNum: number
    /**
     * 设备数量
     */
    equipmentNum: number
    /**
     * 传感器数量
     */
    sensorNum: number
}
export interface ILargeScreenGetAlarmStatisticsParams {
    areaId: number
    companyId: number
}

export interface ILargeScreenGetAlarmStatisticsItem {
    alarmNum: number
    date: string
}

export type ILargeScreenGetAlarmStatisticsParamsData =
    ILargeScreenGetAlarmStatisticsItem[]


export interface ILargeScreenAddParentCompanyFileParams {
    fileId: number
    id: number
}    


//#region 总公司

export interface IProvinceCompanyParams {
    companyId?: number
    provinceName?: string
}

export interface ILargeScreenGetAlarmRankingItem {
    companyName: string
    num: string
}

export type ILargeScreenGetAlarmRankingParams = IProvinceCompanyParams

export type ILargeScreenGetAlarmRankingData = ILargeScreenGetAlarmRankingItem[]

export type ILargeScreenGetEquipmentAlarmStatistics = IProvinceCompanyParams

export interface ILargeScreenGetEquipmentAlarmStatisticsData {
    calarmCount: number
    dalarmCount: number
    onlineCount: number
}

export interface IProvinceListItem {
    cityList: any[]
    /**
     * 公司数量
     */
    companyNum: number
    /**
     * 设备数量
     */
    equipmentNum: number
    /**
     * 省/市
     */
    name: string
    /**
     * 传感器数量
     */
    sensorNum: number
}

export type ILargeScreenGetLargeScreenStatistics = IProvinceCompanyParams

export interface ICountrySummary {
    /**
     * 城市数量
     */
    cityNum: number
    /**
     * 故障诊断数量
     */
    diagnosisNum: number
    /**
     * 设备数量
     */
    equipmentNum: number
    /**
     * 传感器数量
     */
    sensorNum: number
}

export interface ILargeScreenGetLargeScreenStatisticsData extends ICountrySummary {
    /**
     * 省统计
     */
    provinceList: IProvinceListItem[]
}

export type ILargeScreenGetMachineStatisticsParams = IProvinceCompanyParams

export interface ILargeScreenGetMachineStatisticsData {
    areaId: number
    companyId: number
    /**
     * 故障诊断数量
     */
    diagnosisNum: number
    /**
     * 设备数量
     */
    equipmentNum: number
    /**
     * 传感器数量
     */
    sensorNum: number
}

export interface ISensorScreenDTO {
    battery
    createTime
    equipmentId
    mappingId
    mappingName
    sensorId
    tem
    vx
    vy
    vz
}

export interface IMachineListItem {
    equipmentId: number
    equipmentName: string
    sensorItem: ICommonStatusData[]
    sensorList: ISensorScreenDTO[]
}

export interface ILargeScreenGetMachineStatisticsData {
    equipment: ICommonStatusData
    machineList: IMachineListItem[]
    sensor: ICommonStatusData
}

export interface ILargeScreenGetSensorRankingItem {
    companyName: string
    num: number
}

export type ILargeScreenGetSensorRankingParams = IProvinceCompanyParams

export type ILargeScreenGetSensorRankingData = ILargeScreenGetSensorRankingItem[]


export interface ILargeScreenGetCompanyListByProvinceParams {
    provinceName: string
}

export interface ILargeScreenCompanyItem {
    address?: string
    city?
    code?
    fileId?
    fileUrl?
    id: number
    name: string
    parentId?
    province?
    remark?
    status?
    userName?
}

export type ILargeScreenGetCompanyListByProvinceData =
    ILargeScreenCompanyItem[]

export interface ILargeScreenAlaramHistoryParams {
    companyId: number
}

export interface ILargeScreenGetMappingAlarmStatisticsRecordParams {
    companyId: number
}

export interface IMappingAlarmStatisticsItem {
    /**
     * C级报警数量
     */
    alarmLevelc: number
    /**
     * D级报警数量
     */
    alarmLeveld: number
    /**
     * 时间
     */
    dayTime: string
}

export type ILargeScreenGetMappingAlarmStatisticsRecordData =
    IMappingAlarmStatisticsItem[]

//#endregion