/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * 编译环境
 */
export const Env: 'dev' | 'release' = process.env['VUE_APP_ENV']

/**
 * api请求base地址
 */
export const API_BASE_URL: string = process.env['VUE_APP_API_BASE_URL']


export const IsRelease = Env === 'release'


export const InDebug = !!localStorage.getItem('jj_debug')