<template>
    <el-button type="primary" icon="el-icon-search" size="small" v-bind="$attrs" v-on="$listeners">
        {{ $t('buttons.search') }}
    </el-button>
</template>
<script>
export default {
    name: 'ButtonSearch'
}
</script>
