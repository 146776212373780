import { formatDateTime } from '@/utils/datetime'
import { groupToArrayBy, groupToArrayWith } from '@/utils/lang'
import _ from 'lodash'
import moment from 'moment'

export function trendProcess(data, removeResend1MinData = false) {
    let dataGroups: any[][] = []
    if (removeResend1MinData) {
        data = _.map(data, item => {
            return {
                ...item,
                _m: moment(item.x)
            }
        })
        // group一分钟内的数据
        dataGroups = groupToArrayWith(data, (acc, item) => {
            return item._m.diff(acc[0]._m, 'seconds') < 60
        })
    } else {
        // 只group相同的数据
        dataGroups = groupToArrayBy(data, (item: any) => item.x)
    }
    data = _.map(dataGroups, items => {
        let item = items[0]
        return {
            ...item,
            x: formatDateTime(item.x),
            hasDuplicate: items.length > 1,
        }
    })
    return {
        data,
        dataGroups,
    }
}
