/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IPageData, IPageParams } from "../types"

/**
 * $t('enum.E_OnlineOffline.All_')
 * $t('enum.E_OnlineOffline.Online')
 * $t('enum.E_OnlineOffline.Offline')
 */
export enum E_OnlineOffline {
    All_ = -1,
    Online = 1,
    Offline = 0,
}

export interface IGetDeviceListParams extends IPageParams {
    areaId?: number
    companyId?: number
}

export interface IGetDeviceListItem {
    areaId: number;
    equipmentLevel: string;
    equipmentTypeId: number;
    id: number;
    maintenanceTime: number;
    modelId: string;
    /**
     * 模型图片
     */
    modeImg?: string
    /**
     * 设备名称
     */
    name: string;
    /**
     * 1在线0离线
     */
    online: E_OnlineOffline;
}

export type IGetDeviceListData = IPageData<IGetDeviceListItem>

export interface IUploadMappingFileParams {
    /**
     * 传感器ID
     */
    id: number
    /**
     * 上传的图片id
     */
    fileIdArray: number[]
}

export enum E_SensorChartType {
    Angle = 10,
    Battery = 11,
}

export interface IGetSensorBatteryParams {
    sensorId: number
    beginTime: string
    endTime: string
    chartType: E_SensorChartType
}

export interface ISensorDataItem {
    mappingId: number
    waveId: number
    x: string
    y?: number
    y1?: number
    y2?: number
    y3?: number
    aZ: number
}

export type IGetSensorBatteryData = ISensorDataItem[]

export interface IScreenTableItem {
    mappingId
    pointName
    sensorId
    equipmentId
    equipmentName
    areaId
    areaName
    companyId
    companyName
    componentId
    componentName
    sensorName
    sensorType
    location
}
export type IScreenTableData = IScreenTableItem[]
